import React, { useEffect, useState } from "react";
import "./DashBoard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GiWoodPile } from "react-icons/gi";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import Card from "../../../globalcomponents/Admincardslice/Card";
import { LiaCartArrowDownSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserController } from "../../../Redux/controllers/UserController";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import TickPlacementBars from "../../../globalcomponents/Barchart/DashboaedChart";
import Utility from "../../../Utils/Utility";
import { logOut } from "../../../Redux/Slice/userSlice";

export default function DashBoard() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const navigate = useNavigate();
  const [inventoryStatisticsData, setInventoryStatisticsData] = useState({});

  const getInventoryStatistics = async () => {

    try {
      const res = await UserController.inventoryStatistics(token);
      setInventoryStatisticsData(res?.data?.data);

    } catch (error) {
      Utility.eToast( error?.response?.data?.message || error?.message);
      if ( error?.response?.data?.message === "token has expired , please login again") {
        dispatch(logOut());
        localStorage.clear()
        navigate("/")
      }
    } finally {

    }
  }


  useEffect(() => {
    getInventoryStatistics();
  }, [])

  // console.log("inventoryStatisticsData", inventoryStatisticsData);

  const shortenMonthValues = (data) => {

    return data.map(item => {
      const [month, year] = item?.month.split(" ");
      const shortMonth = month?.slice(0, 3);
      const shortYear = year?.slice(-2);
      return {
        ...item,
        month: `${shortMonth} ${shortYear}`
      };
    });
  };

  const [requestTimePeriod, setRequestTimePeriod] = useState("6-month");

  const [materialTimePeriod, setMaterialTimePeriod] = useState("6-month")

  let item;

  if (requestTimePeriod === "6-month") {
    item = (inventoryStatisticsData?.requestsStats?.lastSixMonthsRequests?.slice(6, 12)) ||
      [
        {
          "month": "February 2024",
          "count": 0
        },
        {
          "month": "March 2024",
          "count": 0
        },
        {
          "month": "April 2024",
          "count": 0
        },
        {
          "month": "May 2024",
          "count": 0
        },
        {
          "month": "June 2024",
          "count": 0
        },
        {
          "month": "July 2024",
          "count": 0
        }
      ]
  }
  else if (requestTimePeriod === "year") {
    item = (inventoryStatisticsData?.requestsStats?.lastSixMonthsRequests) ||
      [
        {
          "month": "February 2024",
          "count": 0
        },
        {
          "month": "March 2024",
          "count": 0
        },
        {
          "month": "April 2024",
          "count": 0
        },
        {
          "month": "May 2024",
          "count": 0
        },
        {
          "month": "June 2024",
          "count": 0
        },
        {
          "month": "July 2024",
          "count": 0
        }
      ]
  }


  const dataset = shortenMonthValues(item);


  let item2;

  if (materialTimePeriod === "6-month") {
    item2 = (inventoryStatisticsData?.materialsStats?.lastSixMonthsMaterials?.slice(6, 12)) || [
      {
        "month": "February 2024",
        "count": 0
      },
      {
        "month": "March 2024",
        "count": 0
      },
      {
        "month": "April 2024",
        "count": 0
      },
      {
        "month": "May 2024",
        "count": 0
      },
      {
        "month": "June 2024",
        "count": 3
      },
      {
        "month": "July 2024",
        "count": 0
      }
    ]
  }
  else if (materialTimePeriod === "year") {
    item2 = (inventoryStatisticsData?.materialsStats?.lastSixMonthsMaterials) || [
      {
        "month": "February 2024",
        "count": 0
      },
      {
        "month": "March 2024",
        "count": 0
      },
      {
        "month": "April 2024",
        "count": 0
      },
      {
        "month": "May 2024",
        "count": 0
      },
      {
        "month": "June 2024",
        "count": 3
      },
      {
        "month": "July 2024",
        "count": 0
      }
    ]
  }

  const dataset2 = shortenMonthValues(item2)


  return (
    <Container className="dashboardmaincontainer">
      <Row className="mainRow">
        <Col className="Card">
          <Row style={{ padding: "0" }}>
            <Col style={{ padding: "0" }}>
              <Card
                name={"Material Request"}
                icon={<GiWoodPile />}
                ordernumber={inventoryStatisticsData?.materialsStats?.totalMaterials}
                customernumber={[
                  inventoryStatisticsData?.materialsStats?.totalMaterialsThisWeek,

                  inventoryStatisticsData?.materialsStats?.totalMaterialsThisMonth,

                  inventoryStatisticsData?.materialsStats?.totalMaterialsThisYear
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{ padding: "0" }}>
              <Card
                name={"Request"}
                icon={<LiaCartArrowDownSolid />}
                ordernumber={inventoryStatisticsData?.requestsStats
                  ?.totalRequests}
                customernumber={[
                  inventoryStatisticsData?.requestsStats
                    ?.totalRequestsThisWeek,

                  inventoryStatisticsData?.requestsStats
                    ?.totalRequestsThisMonth,

                  inventoryStatisticsData?.requestsStats
                    ?.totalRequestsThisYear
                ]}
              />
            </Col>
          </Row>
        </Col>
        <Col className="firstgraph">
          <div className="graphtitle">
            <div>
              <p style={{ color: "#642315" }}>Status</p>
            </div>
            <div>
              <p>Last Month</p>
            </div>
          </div>
          <PieChart
            series={[
              {
                data: [
                  { color: "#fefefe" },
                  {
                    id: 0,
                    value: inventoryStatisticsData?.requestsStats?.lastMonthStatusCounts?.approved,
                    label: "Approved",
                    color: "#3B82F6",
                  },
                  {
                    id: 1,
                    value: inventoryStatisticsData?.requestsStats?.lastMonthStatusCounts?.pending,
                    label: "Pending",
                    color: "#F59E0B",
                  },
                ],
              },
            ]}
            height={200}
          />
        </Col>
      </Row>

      <Row className="d-flex gap-4">
        <Col className="firstGraph">
          <div className="graphtitle">
            <div>
              <p style={{ color: "#642315" }}>Material Request</p>
            </div>
            <select
              name="period"
              id="period"
              data-placeholder="This week"
              className="cardselect"
              value={requestTimePeriod}
              onChange={(e) => (
                setRequestTimePeriod(e.target.value))}
            >
              <option value="6-month">Last 6 Month</option>
              <option value="year">Last Year</option>
            </select>
          </div>
          <BarChart
            borderRadius={8}
            dataset={dataset}
            yAxis={[{
              scaleType: 'band', dataKey: 'month',
              categoryGapRatio: 0.8,
            }]}
            series={[{ dataKey: 'count' }]}
            layout="horizontal"
            grid={{ vertical: true }}
            height={400}
          />
        </Col>
        <Col className="firstGraph">
          <div className="graphtitle">
            <div>
              <p style={{ color: "#642315" }}>Materials</p>
            </div>
            <div>
              <select
                name="period"
                id="period"
                data-placeholder="This week"
                className="cardselect"
                value={materialTimePeriod}
                onChange={(e) => (
                  setMaterialTimePeriod(e.target.value))}
              >
                <option value="6-month">Last 6 Month</option>
                <option value="year">Last Year</option>
              </select>
            </div>
          </div>
          {/* <TickPlacementBars data={dataset2} accessKey = "count" /> */}
          <BarChart
            grid={{ horizontal: true }}
            sx={{ paddingTop: "20px" }}
            dataset={dataset2}
            borderRadius={15}
            series={[{ dataKey: "count", color: ["#642315"] }]}
            height={400}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "month",
                categoryGapRatio: 0.85,
              },
            ]}
            leftAxis={{
              disableLine: true,
              disableTicks: true,
            }}
            bottomAxis={{
              disableLine: true,
              disableTicks: true,
            }}
          />
        </Col>
      </Row>

      <Row className="dashboardsecondrow">
        <Col className="dashboardaction">Quick Action</Col>
        <div className="lineaction"></div>
      </Row>
      <Row className="dashboadrrow">
        <Col
          md={6}
          sm={6}
          xs={12}
          className="leftdashboardbutton"
          onClick={() => navigate("/productform")}
        >
          <GlobalButton
            name="Add Material"
            background="#E4CDAA"
            color="#642315"
            fontFamily="Roboto"
            paddingLeft="3rem"
            paddingRight="3rem"
            fontWeight="500"
            fontSize="large"
            borderradius="5px"
            width="fit-content"
          />
        </Col>
        <Col
          md={6}
          sm={6}
          xs={12}
          className="rightdashboardbutton"
          onClick={() => navigate("/CheckMaterialRequest")}
        >
          <GlobalButton
            name="Check Material Requests"
            background="#E4CDAA"
            color="#642315"
            fontFamily="Roboto"
            paddingLeft="3rem"
            paddingRight="3rem"
            fontWeight="500"
            fontSize="large"
            borderradius="5px"
            width="fit-content"
          />
        </Col>
      </Row>
    </Container>
  );
}
