import React, { useState, useRef } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MdOutlineFileUpload } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import "./ExpenseForm.css";
import { UserController } from "../../../Redux/controllers/UserController";
import Utility from "../../../Utils/Utility";
import { AccountsController } from "../../../Redux/controllers/AccountsController";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";

const expenseCategory = [
  "Salaries and Wages",
  "Buy Raw Materials",
  "Rent and Utilities",
  "Advertising and Marketing",
  "Other",
];

export default function ExpenseForm(props) {
  const [expenseData, setExpenseData] = useState({
    trPaymentID: "",
    trDate: "",
    trType: "expense",
    trDescription: "",
    trAdditionalNotes: "",
    trPaymentMethod: "",
    trExCategory: "",
    trExCategoryName: "",
    trExPartyName: "",
    trExPartyEmail: "",
    trExPartyMobileNo: "",
    trExTotalAmount: 0,
    trDoc: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { token } = useState((st) => st?.user);

  const handleChange = (e) => {
    setExpenseData({ ...expenseData, [e.target.name]: e.target.value });
    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: "" });
    }
  };

  // console.log("expenseData",expenseData);

  const navigate = useNavigate();

  const handleFileChange = async (e) => {
    const filedata = e.target.files[0];
    if (!filedata) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("doc", filedata);
      const { data } = await UserController.SingleImageUploader(formData);
      setExpenseData((prevDetails) => ({
        ...prevDetails,
        trDoc: data?.doc,
      }));
      Utility.sToast(data?.message);
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!expenseData.trExCategory) {
      newErrors.trExCategory = "Transaction Category is required";
    } else if (
      expenseData?.trExCategory === "Other" &&
      !expenseData.trExCategoryName
    ) {
      newErrors.trExCategoryName = "Enter Transaction Category Name.";
    }
    if (!expenseData.trDate) newErrors.trDate = "Transaction Date is required";
    if (!expenseData.trDescription)
      newErrors.trDescription = "Transaction Description is required";
    if (!expenseData.trExPartyName)
      newErrors.trExPartyName = "Party/Name is required";
    if (!expenseData.trExPartyMobileNo) {
      newErrors.trExPartyMobileNo = "Party Mobile Number is required";
    } else if (expenseData.trExPartyMobileNo.length !== 10) {
      newErrors.trExPartyMobileNo = "Mobile Number should be 10 digits";
    }
    if (!expenseData.trPaymentMethod)
      newErrors.trPaymentMethod = "Payment Method is required";

    if (!expenseData.trExTotalAmount)
      newErrors.trExTotalAmount = "Total Transaction Amount is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      if (expenseData.trExCategory === "Other") {
        expenseData.trExCategory = expenseData.trExCategoryName;
      }
      setLoading(true);
      const { data } = await AccountsController.createTransactionDetailsAPI(
        expenseData,
        token
      );
      // console.log("data",data);
      Utility.sToast(data?.message);
      navigate(-1);
    } catch (error) {
      Utility?.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container className="saleformcontainer">
        <Row className="saleTop">
          <Col sm={6} className="sText">
            Expense Transaction Details
          </Col>
          <Col sm={6} className="crossform" onClick={() => navigate(-1)}>
            <RxCross1 id="crossicon" style={{ color: "black" }} />
          </Col>
        </Row>
        <Row id="subadminmodalbody">
          <Col>
            <Form onSubmit={handleSubmit}>
              <Row id="ADNsubadminRow">
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">
                    Transaction Category
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="trExCategory"
                    onChange={handleChange}
                    isInvalid={errors.trExCategory}
                  >
                    <option>Choose Transaction Category</option>
                    {expenseCategory?.map((category) => (
                      <option value={category} key={category}>
                        {category}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.trExCategory}
                  </Form.Control.Feedback>
                </Col>
                {expenseData.trExCategory === "Other" && (
                  <Col xs={12} md={6}>
                    <Form.Label id="ADNsubadminForm">
                      Enter Transaction Category
                    </Form.Label>
                    <Form.Control
                      placeholder="Enter Transaction Category Name"
                      id="subadminFormcontrol"
                      name="trExCategoryName"
                      onChange={handleChange}
                      isInvalid={errors.trExCategoryName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.trExCategoryName}
                    </Form.Control.Feedback>
                  </Col>
                )}
              </Row>

              <Row id="ADNsubadminRow">
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Transaction Date</Form.Label>
                  <br />
                  <Form.Control
                    type="date"
                    id="subadminFormcontrol"
                    className="custom-date-input"
                    name="trDate"
                    onChange={handleChange}
                    isInvalid={errors.trDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.trDate}
                  </Form.Control.Feedback>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">
                    Transaction Description
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter transaction description"
                    id="subadminFormcontrol"
                    name="trDescription"
                    onChange={handleChange}
                    isInvalid={errors.trDescription}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.trDescription}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row id="subadminmodalbody1">
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">
                    Transaction Party/Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name of Customer/Supplier"
                    id="subadminFormcontrol"
                    name="trExPartyName"
                    onChange={handleChange}
                    isInvalid={errors.trExPartyName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.trExPartyName}
                  </Form.Control.Feedback>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">
                    Party Mobile Number
                  </Form.Label>
                  <br />
                  <Form.Control
                    type="number"
                    id="subadminFormcontrol"
                    className="custom-date-input"
                    placeholder="Enter Supplier Number"
                    name="trExPartyMobileNo"
                    onChange={handleChange}
                    isInvalid={errors.trExPartyMobileNo}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.trExPartyMobileNo}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row id="subadminmodalbody1">
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Payment Method</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={handleChange}
                    name="trPaymentMethod"
                    isInvalid={!!errors.trPaymentMethod}
                  >
                    <option>Choose Payment Method</option>
                    <option value="bank_transfer_or_upi">
                      Upi/Bank Transfer
                    </option>
                    <option value="cash">Cash</option>
                    <option value="check">Check</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.trPaymentMethod}
                  </Form.Control.Feedback>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Payment ID</Form.Label>
                  <br />
                  <Form.Control
                    type="text"
                    id="subadminFormcontrol"
                    className="custom-date-input"
                    placeholder="Enter transaction ID"
                    name="trPaymentID"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row id="ADNsubadminRow">
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">
                    Total Transaction Amount
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter total amount"
                    id="subadminFormcontrol"
                    name="trExTotalAmount"
                    onChange={handleChange}
                    isInvalid={!!errors.trExTotalAmount}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.trExTotalAmount}
                  </Form.Control.Feedback>
                </Col>

                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">
                    Additional Notes (if any)
                  </Form.Label>
                  <Form.Control
                    placeholder="Any additional notes or comment regarding the sale"
                    id="subadminFormcontrol"
                    name="trAdditionalNotes"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row id="ADNsubadminRow">
                <Col xs={12} md={12}>
                  <Form.Label id="ADNsubadminForm">
                    Upload Bill (if any)
                  </Form.Label>
                  <Form.Control
                    type="file"
                    id="subadminFormcontrol"
                    onChange={handleFileChange}
                  />
                </Col>
              </Row>
              <Row className="sallastend">
                {/* <div style={{ width: "fit-content" }}>
                  <GlobalButton
                    name="Cancel"
                    background="#E4CDAA"
                    color="#642315"
                  />
                </div> */}
                <div style={{ width: "fit-content" }}>
                  {loading ? (
                    <CircularIndeterminate size={"30px"} />
                  ) : (
                    <GlobalButton
                      name="Submit"
                      background="#dc2329"
                      color="#fff"
                      onclick={handleSubmit}
                    />
                  )}
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
