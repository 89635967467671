import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Container } from "react-bootstrap";
import "./NotificationModal.css";
import { IoNotifications } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import { useSelector } from "react-redux";
import { UserController } from "../../../../Redux/controllers/UserController";
import CircularIndeterminate from "../../../../globalcomponents/Spinner/Spinner";
import Utility from "../../../../Utils/Utility";

const NotificationModal = (props) => {
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.userInfo._id);
  console.log(
    "Here is the user id user Id =++++++++++++++=========+++++++++++++===",
    userId
  );
  const [allNotification, setAllNotification] = useState([]);
  const [loading, setLoading] = useState(false);

  const deleteSingleNotification = async (token, id) => {
    try {
      const resp = await UserController.deleteNotificationById(token, id);
      // console.log("Resp", resp);
      getNotification();
    } catch (error) {
      console.log("Error", error);
    }
  };

  const getNotification = async () => {
    try {
      setLoading(true);
      const res = await UserController.getAllNotification(token, userId);
      // console.log("RESP", res.data.data);
      setLoading(false);
      setAllNotification(res.data.data);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNotification();
  }, []);

  const deleteAllNotification = async () => {
    try {
      const res = await UserController.deleteAllNotificationById(token, userId);
      console.log("RESP", res);
      getNotification();
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
      <Container className="p-2">
        <Modal id="transactionModal" {...props} size="lg">
          <Modal.Header closeButton style={{ background: "#E4CDAA" }}>
            <Modal.Title style={{ color: "#642315E5", background: "#E4CDAA" }}>
              {/* <IoNotifications /> */}
              <p>Notifications</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {allNotification.length > 0 ? (
              <div className="all-button">
                <div className="twoButton">
                  <button className="Button">All</button>
                </div>
                <button
                  disabled={allNotification.length === 0}
                  className="Button"
                  onClick={deleteAllNotification}
                >
                  Delete all{" "}
                </button>
              </div>
            ) : (
              ""
            )}

            {!loading ? (
              <Row className="allNotification">
                {allNotification.length ? (
                  allNotification.map((notification, index) => (
                    <Row className="Notification" key={index}>
                      <Col>
                        <Row className="notificationCrossIcon">
                          <RxCrossCircled
                            style={{height:"24px",width:"50px"}}
                            className="cross"
                            onClick={() =>
                              deleteSingleNotification(token, notification?._id)
                            }
                          />
                        </Row>
                        <Row>
                          <div className="Inventory">{notification.title}</div>
                          <div>{notification.body}</div>
                        </Row>
                        <Row className="notificationDateCross">
                          <h6 style={{width:"100px", padding:0}}> {Utility.formatDate(notification.createdAt)}</h6>
                        </Row>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <div className="notificationNotFound">
                    Notification not available
                  </div>
                )}
              </Row>
            ) : (
              <div className="spinner">
                <CircularIndeterminate />
              </div>
            )}
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default NotificationModal;
