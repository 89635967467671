import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  InputGroup,
  ButtonGroup,
  Image,
} from "react-bootstrap";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoFilter } from "react-icons/io5";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import "./AcOrderHistory.css";
import { AccountsController } from "../../../Redux/controllers/AccountsController";
import { useDispatch, useSelector } from "react-redux";
import Utility from "../../../Utils/Utility";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import ViewOrderHistory from "../ViewOrderHistory/ViewOrderHistory";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";
import { logOut } from "../../../Redux/Slice/userSlice";

export default function AcOrderHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((st) => st?.user);
  const [allOrders, setAllOrders] = useState([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedDepartment, setSelcetdDepartment] = useState("all-order");
  // const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleView = (item) => {
    navigate("/ViewOrderHistory", { state: { selectedOrder: item } });
    // setShowViewModal(true);
    // setSelectedOrder(item);
  };
  const getAllOrders = async () => {
    try {
      setLoading(true);
      console.log(token);
      const { data } = await AccountsController.getOrderDetailsAPI(token);
      setAllOrders(data?.data);
      console.log(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Utility.eToast(error?.response?.data?.message || error?.message);
      if (
        error?.response?.data?.message ===
        "token has expired , please login again"
      ) {
        dispatch(logOut());
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllOrders();
  }, []);
  const column = [
    {
      accessorKey: "slNo",
      header: "Sl No",
      size: 30,
    },
    {
      accessorKey: "orderid",
      header: "Order ID",
      size: 50,
    },
    {
      accessorKey: "price",
      header: "Price",
      size: 50,
    },
    {
      accessorKey: "deadline",
      header: "Deadline",
      size: 50,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 50,
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 150,
    },
  ];
  console.log("All order", allOrders);

  const data = allOrders
    ?.filter((item) =>
      selectedDepartment === "all-order"
        ? true
        : item?.deliveryStatus === selectedDepartment
    )
    ?.map((item, i) => ({
      slNo: i + 1,
      orderid: item?.orderID || "N/A",
      price: Utility.MoneyFormatter(item?.paymentDetails?.totalAmount),
      deadline: Utility.formatDate(item?.estimatedDeliveryDate) || "N/A",
      status: (
        <GlobalButton
          disabledBgColor={Utility.getStatusColor(item?.deliveryStatus)}
          disabled={true}
          name={
            item?.deliveryStatus?.charAt(0).toUpperCase() +
              item?.deliveryStatus?.slice(1) || "N/A"
          }
          width="6rem"
          padding="2px"
          color="#fff"
        />
      ),
      action: (
        <GlobalButton
          name=" View"
          background={"#dc2329"}
          color="#fff"
          onclick={() => handleView(item)}
        />
      ),
    }));

  const employeeType = [
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "confirmed",
      label: "Confirmed",
    },
    {
      value: "processing",
      label: "Processing",
    },
    {
      value: "shipped",
      label: "Shipped",
    },
    {
      value: "delivered",
      label: "Delivered",
    },
  ];

  return (
    <>
      {/* {showViewModal ? (
        <ViewOrderHistory
          show={showViewModal}
          onHide={() => setShowViewModal(false)}
          selectedOrder={selectedOrder}
        />
      ) : ( */}
      <Container id="transaction-primary-container">
        {/* transction with icons */}

        <Row className="second-box">
          <Col
            md={4}
            xs={6}
            sm={6}
            className="transaction-list"
            style={{ color: "black" }}
          >
            Order Details
          </Col>
          <Col lg={3} md={4} xs={6} sm={5}>
            <Form.Select
              aria-label="Default select example"
              name="trExCategory"
              onChange={(e) => setSelcetdDepartment(e.target.value)}
              id="filterBox"
            >
              <option value={"all-order"}>Filter</option>
              {employeeType?.map((item) => (
                <option value={item?.value} key={item?.label}>
                  {item?.label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        {!loading ? (
          <DataTable columns={column} data={data} />
        ) : (
          <div className=" Loader">
            <CircularIndeterminate />
          </div>
        )}
      </Container>
      {/* )} */}
    </>
  );
}
