import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import OrderDetailsModal from "../Modals/OrderDetailsModal/OrderDetailsModal";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import "./OrderHistory.css";
import { UserController } from "../../../Redux/controllers/UserController";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";
import Utility from "../../../Utils/Utility";
import { logOut } from "../../../Redux/Slice/userSlice";

const OrderHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [filter, setFilter] = useState("");
  const token = useSelector((state) => state.user.token);

  const handleModal = (item) => {
    setSelectedMaterial(item);
    setShowModal(true);
  };

  useEffect(() => {
    getMaterialDetails();
  }, [showModal]);

  console.log("Here is the total data t=================", orderData);

  const getMaterialDetails = async () => {
    try {
      setLoading(true);
      const response = await UserController.getMaterialDetailsAPI(token);
      setOrderData(response.data.data);
      setLoading(false);
      console.log(response);
    } catch (error) {
      setLoading(false);
      Utility.eToast(error?.response?.data?.message || error?.message);
      if (
        error?.response?.data?.message ===
        "token has expired , please login again"
      ) {
        dispatch(logOut());
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { accessorKey: "matId", header: "Material ID", size: 100 },
    { accessorKey: "material", header: "Material", size: 100 },
    { accessorKey: "stock", header: "Stock", size: 100 },
    { accessorKey: "color", header: "Color", size: 100 },
    { accessorKey: "size", header: "Size", size: 100 },
    { accessorKey: "pattern", header: "Pattern", size: 100 },
    { accessorKey: "status", header: "Status", size: 100 },
    { accessorKey: "action", header: "Action", size: 100 },
  ];

  const filteredData = orderData.filter((item) => {
    if (filter === "in_stock") {
      return item.status === "in_stock";
    } else if (filter === "out_of_stock") {
      return item.status === "out_of_stock";
    } else {
      return true; // no filter, show all
    }
  });

  const data = filteredData.map((item) => ({
    matId: item?.materialID || "N/A",
    material: item?.name,
    stock: item?.stock + " " + item?.unit || 0,
    color: item?.color ? (
      <div className="responsive-cell">
        <div
          className="color-circle"
          style={{
            backgroundColor: item.color,
          }}
        />
      </div>
    ) : (
      "N/A"
    ),
    size: item?.size || "N/A",
    pattern: item?.pattern ? (
      <div className="responsive-cell">
        <img
          src={item.pattern}
          alt="Pattern"
          className="pattern-circle"
          style={{
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </div>
    ) : (
      "N/A"
    ),
    status: (
      <button
        disabled
        style={{
          backgroundColor: `${
            item?.status === "in_stock" ? "#15B097" : "#EDA145"
          }`,
        }}
        className="material-status-btn"
      >
        {item?.status === "in_stock" ? "In Stock" : "Out of Stock"}
      </button>
    ),
    action: (
      <GlobalButton
        name={"Update"}
        background={"#E4CDAA"}
        color={"#642315"}
        padding={"0"}
        onclick={() => handleModal(item)}
      />
    ),
  }));
  const [loading, setLoading] = useState(false);

  return (
    <Container className="mb-3 mt-3">
      <OrderDetailsModal
        show={showModal}
        onHide={() => setShowModal(false)}
        material={selectedMaterial}
      />
      <Row className="mt-2">
        <Col>
          <h5 style={{ color: "#642315" }}>Inventory</h5>
        </Col>
      </Row>
      <Row className="firstRow">
        <Col xs={12} md={6}></Col>
        <Col
          xs={12}
          md={6}
          className="d-flex  gap-2 justify-content-end align-items-center"
        >
          <Form.Select
            id="filterButton"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="">Select Filter</option>
            <option value="in_stock">In Stock</option>
            <option value="out_of_stock">Out of Stock</option>
          </Form.Select>
          <button
            id="addNew"
            onClick={() => navigate("/productform")}
            className="btn btn-primary"
          >
            <IoMdAddCircleOutline /> Add Material
          </button>
        </Col>
        {/* <Col className="rightButton"></Col> */}
      </Row>

      <Row className="mt-3">
        <Col>
          {!loading ? (
            <DataTable columns={columns} data={data} />
          ) : (
            <div className=" Loader">
              <CircularIndeterminate />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default OrderHistory;
