import React, { useState } from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import "./ViewExpenseData.css";
import { RxCross1 } from "react-icons/rx";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { FaDownload } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import Utility from "../../../Utils/Utility";
import { AccountsController } from "./../../../Redux/controllers/AccountsController";
import { useSelector } from "react-redux";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";

export default function ViewExpenseData(props) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((st) => st?.user);
  const item = location?.state;
  console.log(item);
  const navigate = useNavigate();
  if (!item) {
    navigate(-1);
  }
  const handleDownloadBill = async () => {
    try {
      setLoading(true);
      const { data } = await AccountsController.fetchExpenseBillAPI(
        token,
        item?._id
      );
      const fileUrl = data?.data;

      // Fetch the file data from the URL
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Determine the file extension from the MIME type
      const mimeType = blob.type;
      const extension = mimeType.split("/")[1];
      const filename = `bill.${extension}`;

      // Create a URL for the Blob
      const blobUrl = window.URL.createObjectURL(blob);

      // Create a link element and set its href to the Blob URL
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", filename); // Set the file name
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container id="SalesContainer">
        <Row className="saleviewtop">
          <Col sm={6} className="leftconatint" style={{ color: "black" }}>
            Transaction Expenses Details
          </Col>
          <Col sm={6} className="rightconatint">
            <RxCross1 onClick={() => navigate(-1)} />
          </Col>
        </Row>
        <Row id="container-row">
          <Col>
            <Row id="heading-row">
              <Col id="heading-col" style={{ color: "black" }}>
                Transaction Details
              </Col>
            </Row>
            <hr />
            <Row className="rowData">
              <Col className="key">ID</Col>
              <Col id="view-col">{item?.trID ? "#" + item?.trID : "N/A"}</Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Date</Col>
              <Col id="view-col">
                {Utility?.formatDate(item?.trDate) || "N/A"}
              </Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Category</Col>
              <Col id="view-col">{item?.trExCategory || "N/A"}</Col>
            </Row>

            <Row id="heading-row">
              <Col id="heading-col" style={{ color: "black" }}>
                Transaction Description
              </Col>
            </Row>
            <hr />
            <Row className="rowData">
              <Col className="key">Description</Col>
              <Col id="view-col">{item?.trDescription || "N/A"}</Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Party</Col>
              <Col id="view-col">{item?.trExPartyName || "N/A"}</Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Party Mobile Number</Col>
              <Col id="view-col">{item?.trExPartyMobileNo || "N/A"}</Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Payment Method</Col>
              <Col id="view-col">
                {(item?.trPaymentMethod &&
                  Utility.getPaymentMethod(item?.trPaymentMethod)) ||
                  "N/A"}
              </Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Amount</Col>
              <Col id="view-col-num">
                {Utility.MoneyFormatter(item?.trExTotalAmount)}
              </Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Payment ID</Col>
              <Col id="view-col">{item?.trDescription || "N/A"}</Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Additional Info</Col>
              <Col id="view-col">{item?.trAdditionalNotes || "N/A"}</Col>
            </Row>

            <Row id="heading-row">
              <Col id="heading-col" style={{ color: "black" }}>
                View Bill
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className="invoiceform">
                {loading ? (
                  <CircularIndeterminate size={"30px"} />
                ) : (
                  <GlobalButton
                    name={"Download Transaction Bill"}
                    background="#dc2329"
                    color="#fff"
                    icon={<FaDownload />}
                    marginBottom="2rem"
                    // marginLeft="auto"
                    onclick={handleDownloadBill}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {/* <Row className="invoiceform">
          <div style={{ width: "fit-content", marginBottom: "1rem" }}>
            <GlobalButton
              name={"Download Transaction Bill"}
              background="#F4F3F0"
              color="#642315"
              icon={<FaDownload />}
            />
          </div>
        </Row> */}
      </Container>
    </>
  );
}
