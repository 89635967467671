import React, { useState, useEffect } from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import "./ViewPayrollForm.css";
import { RxCross1 } from "react-icons/rx";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";
// import DataTable from "../../../../../../globalcomponents/DataTable/DataTable";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import Utility from "../../../Utils/Utility";
import { AccountsController } from "./../../../Redux/controllers/AccountsController";
import { useSelector } from "react-redux";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { FaDownload } from "react-icons/fa";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";
import UpdateSalery from "../Modals/UpdateSalery/UpdateSalery";
export default function ViewPayrollForm(props) {
  const [salaryDetails, setSalaryDetails] = useState([]);
  const [updateSaleryModal, setUpdateSaleryModal] = useState(false);
  const [spinner, setSppiner] = useState(false);
  const { token } = useSelector((st) => st?.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [loadingStates, setLoadingStates] = useState({});

  const employee = location?.state;
  const getSalaryDetailsOfEmployee = async () => {
    try {
      const { data } = await AccountsController.getSalaryDetailsOfEmployeeAPI(
        token,
        employee?._id
      );
      setSalaryDetails(data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    getSalaryDetailsOfEmployee();
  }, [updateSaleryModal]);

  const ShowUpdateSaleryModal = () => {
    setUpdateSaleryModal(true);
  };

  console.log("The data is that is provided =====+++++++", employee);

  const column = [
    {
      accessorKey: "slNo",
      header: "SL NO",
      size: 50,
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 50,
    },

    {
      accessorKey: "salaryAmount",
      header: " Salary Amount",
      size: 50,
    },
    {
      accessorKey: "notes",
      header: "Notes",
      size: 150,
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 150,
    },
  ];

  const handleDownload = async (id) => {
    try {
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: true,
      }));
      const response = await AccountsController.DownLoadSalery(id);
      console.log(response);
      const ClaudinaryUrl = response?.data?.data;
      setSppiner(false);
      window.open(ClaudinaryUrl, "_blank");
    } catch (error) {
      Utility?.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    }
  };

  const data = salaryDetails?.map((item, i) => ({
    slNo: i + 1,
    date: (item?.createdAt && Utility.formatDate(item?.createdAt)) || "N/A",
    // salaryAmount: (item?.netSalary && "₹ " + item?.netSalary) || "N/A",
    salaryAmount: Utility.MoneyFormatter(item?.netSalary),
    notes: item?.notes || "N/A",

    action: (
      <div className="d-flex  gap-3 ">
        {loadingStates[item._id] ? (
          <CircularIndeterminate size={30} />
        ) : (
          <GlobalButton
            name="Salary Slip"
            background="#dc2329"
            color="#fff"
            fontFamily="Roboto"
            paddingLeft="0.5rem"
            paddingRight="0.5rem"
            icon={<FaDownload />}
            onclick={() => handleDownload(item._id)}
          />
        )}
      </div>
    ),
  }));

  return (
    <>
      <Container className="ViewPayrollContainer">
        {updateSaleryModal && (
          <UpdateSalery
            show={updateSaleryModal}
            onHide={() => setUpdateSaleryModal(false)}
            employee={employee}
          />
        )}
        <Row id="viewSaleryHeader">
          <Col className="topdataofview" style={{ color: "black" }}>
            Employee Data
          </Col>
          <Col id="subadmincrossicon">
            <RxCross1 id="crossicon" onClick={() => navigate(-1)} />
          </Col>
        </Row>
        <Row id="container-row">
          <Row id="heading-row">
            <Col id="heading-col" style={{ color: "black" }}>
              Personal Details
            </Col>
          </Row>
          <hr id="hr-tag" />

          <Row>
            <Col>Full Name</Col>
            <Col id="view-col">{employee?.name || "N/A"}</Col>
          </Row>

          <Row>
            <Col>Phone Number</Col>
            <Col id="view-col">{employee?.phoneNo || "N/A"}</Col>
          </Row>
          <Row>
            <Col>Email</Col>
            <Col id="view-col">{employee?.email || "N/A"}</Col>
          </Row>
          <Row>
            <Col xl={6}>DOB</Col>
            <Col id="view-col">
              {(employee?.dob && Utility.formatDate(employee?.dob)) || "N/A"}
            </Col>
          </Row>
          <Row>
            <Col>Address</Col>
            <Col id="view-col">{employee?.address || "N/A"}</Col>
          </Row>

          <Row id="heading-row">
            <Col id="heading-col" style={{ color: "black" }}>
              Employment Details
            </Col>
          </Row>
          <hr id="hr-tag" />

          <Row>
            <Col>Employee ID</Col>
            <Col id="view-col">
              {(employee?.empEmployeeID && "#" + employee?.empEmployeeID) ||
                "N/A"}
            </Col>
          </Row>

          <Row>
            <Col>Date of Hire</Col>
            <Col id="view-col">
              {(employee?.empDateOfHire &&
                Utility.formatDate(employee?.empDateOfHire)) ||
                "N/A"}
            </Col>
          </Row>
          <Row>
            <Col>Department</Col>
            <Col id="view-col">
              {Utility.getDepartment(employee?.empDepartment) || "N/A"}
            </Col>
          </Row>

          <Row id="heading-row">
            <Col id="heading-col" style={{ color: "black" }}>
              Security Information
            </Col>
          </Row>
          <hr id="hr-tag" />
          <Row>
            <Col>Username</Col>
            <Col id="view-col"> {employee?.empUserName || "N/A"}</Col>
          </Row>
          <Row>
            <Col>Password</Col>
            <Col id="view-col">##########</Col>
          </Row>

          <Row id="heading-row">
            <Col id="heading-col" style={{ color: "black" }}>
              Salary Details
            </Col>
          </Row>
          <hr id="hr-tag" />
          <Row>
            <Col>Salary Amount</Col>
            <Col id="view-col">
              {Utility.MoneyFormatter(employee?.empSalaryPerFrequency)}
            </Col>
          </Row>
          <Row>
            <Col>Pay Frequency</Col>
            <Col id="view-col">
              {employee?.empPayFrequency
                ? employee?.empPayFrequency?.charAt(0).toUpperCase() +
                  employee?.empPayFrequency.slice(1)
                : "N/A"}
            </Col>
          </Row>
          <Row>
            <Col>Payment Method</Col>
            <Col id="view-col">
              {employee.empPaymentMethod
                ? Utility.getPaymentMethod(employee.empPaymentMethod)
                : "N/A"}
            </Col>
          </Row>

          {employee?.empPaymentMethod === "bank_transfer_or_upi" && (
            <>
              <Row id="heading-row">
                <Col id="heading-col" style={{ color: "black" }}>
                  Bank Details
                </Col>
              </Row>
              <hr id="hr-tag" />
              <Row>
                <Col>Bank Name</Col>
                <Col id="view-col">{employee?.empBankName || "N/A"}</Col>
              </Row>
              <Row>
                <Col>Account Number</Col>
                <Col id="view-col">{employee?.empAccountNo || "N/A"}</Col>
              </Row>
              <Row>
                <Col>IFCS Code</Col>
                <Col id="view-col">{employee?.empIFSC || "N/A"}</Col>
              </Row>
            </>
          )}

          <Row id="heading-row">
            <Col id="heading-col" style={{ color: "black" }}>
              Salary History
            </Col>
          </Row>
          <hr id="hr-tag" />
        </Row>
        <Row id="Table-Row">
          <DataTable columns={column} data={data} />
        </Row>
        <Row id="button-row">
          <Col id="d-col">
            <GlobalButton
              name="Update Salary"
              background="#dc2329"
              color="#fff"
              fontFamily="Roboto"
              padding="0.5rem"
              borderradius="8px"
              onclick={ShowUpdateSaleryModal}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
