import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ExpensesData from "../TExpensesData/ExpensesData";
import SalesData from "../TSalesData/SalesData";
import { Container } from "react-bootstrap";
import "./AcTransaction.css";

const AcTransaction = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const defaultTab = queryParams.get("tab") || "Expenses";

  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
    navigate(`?tab=${eventKey}`);
  };

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  return (
    <Container className="fullcontainer">
      <Tabs
        activeKey={activeTab}
        onSelect={handleTabSelect}
        id="uncontrolled-tab-example"
        className="mb-3 mt-3 fullcontainer"
      >
        <Tab eventKey="Expenses" title="Expenses Data" className="Expenses">
          <ExpensesData />
        </Tab>
        <Tab eventKey="Sales" title="Sales Data" className="Sales">
          <SalesData navigate={navigate} />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default AcTransaction;
