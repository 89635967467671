import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import "./GenerateInvoice.css";
import { Modal } from "react-bootstrap";
export default function GenerateInvoice(props) {
  const navigate = useNavigate();
  return (
    <>
      <Container id="modal">
        <Row id="container-id">
          <Col>
            <Row id="transaction">
              <Col md={11} xs={10} id="transaction1">
                Invoice Generation
              </Col>
              <Col md={1} xs={2} id="transaction2">
                {" "}
                <RxCross1 id="crossicon" onClick={() => navigate(-1)} />
              </Col>
            </Row>
            <Row id="transaction3">
              <Col>
                <Form>
                  <Row id="transaction4">
                    <Form.Label id="form-lebel">
                      Personal Information
                    </Form.Label>
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Customer Name</Form.Label>
                      <Form.Control placeholder="Mauro Sicard" id="formid123" />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Phone Number</Form.Label>
                      <Form.Control placeholder="123698547000" id="formid123" />
                    </Col>
                  </Row>
                  <Row id="transaction4">
                    <Col xs={12} md={12}>
                      <Form.Label id="transaction5">
                        Shipping Address
                      </Form.Label>
                      <Form.Control
                        placeholder="Pablo Alto, San Francisco, CA 92102, United States of America "
                        id="formid123"
                      />
                    </Col>
                  </Row>
                  <Row id="transaction4">
                    <Col xs={12} md={12}>
                      <Form.Label id="transaction5">Billing Address</Form.Label>
                      <Form.Control
                        placeholder="Pablo Alto, San Francisco, CA 92102, United States of America"
                        id="formid123"
                      />
                    </Col>
                  </Row>
                  <Row id="transaction4">
                    <Form.Label id="form-lebel">Product Information</Form.Label>
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Product Name</Form.Label>
                      <Form.Control
                        placeholder="Harmony Dining Table Set - 4x6 "
                        id="formid123"
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Quantity</Form.Label>
                      <Form.Control placeholder="01" id="formid123" />
                    </Col>
                  </Row>
                  <Row id="transaction4">
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Unit Price</Form.Label>
                      <Form.Control placeholder="$ 25,000" id="formid123" />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Total</Form.Label>
                      <Form.Control placeholder="01" id="formid123" />
                    </Col>
                  </Row>
                  <Row id="transaction4">
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Sub Total</Form.Label>
                      <Form.Control placeholder="$ 50,000" id="formid123" />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Tax</Form.Label>
                      <Form.Control placeholder="8% " id="formid123" />
                    </Col>
                  </Row>
                  <Row id="transaction4">
                    <Col xs={12} md={12}>
                      <Form.Label id="transaction5">
                        Payment in advance
                      </Form.Label>
                      <Form.Control placeholder="$ 54,000" id="formid123" />
                    </Col>
                  </Row>
                  <Row id="transaction4">
                    <Col xs={12} md={12}>
                      <Form.Label id="transaction5">Net Total</Form.Label>
                      <Form.Control placeholder="$ 54,000" id="formid123" />
                    </Col>
                  </Row>
                  <Row id="transaction4">
                    <Col xs={12} md={12}>
                      <Form.Label id="transaction5">Additional Info</Form.Label>
                      <Form.Control
                        type="textarea"
                        placeholder="Thank you for your order! Expect your furniture delivery
                      promptly. We strive for swift service to meet your needs."
                        id="formid123"
                      />
                    </Col>
                  </Row>
                  <Row id="transaction4">
                    <Col xs={12}>
                      <Form.Label id="transaction5">Invoice date</Form.Label>
                      <br />
                      <Form.Control
                        type="date"
                        id="formid123"
                        class="custom-date-input"
                      />
                    </Col>
                  </Row>
                </Form>
                <Row id="buttonrowcol">
                  <Col md={11} xs={4} id="discard-col">
                    <Button className="button">Discard</Button>
                  </Col>
                  <Col md={1} xs={4}>
                    <Button className="button">Generate</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
