import { useEffect, useState } from "react";
import "./SideBarMenu.css";
import { Col, Image, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
// import logo from "../../Admin/assets/dashboard/logo.png";
import mmlogo from "../../Inventory_material_request/assets/dashboard/mmlogo.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbFileSymlink } from "react-icons/tb";
import {
  MdArrowForwardIos,
  MdClose,
  MdOutlineDesktopWindows,
  MdOutlineMailOutline,
  MdOutlinePeopleAlt,
  MdTaxiAlert,
} from "react-icons/md";
import logo from "../../Inventory_material_request/assets/dashboard/logo.png";
import { RiAdminLine, RiBookReadLine, RiContactsLine } from "react-icons/ri";
import { IoCarSportOutline, IoStatsChartOutline } from "react-icons/io5";
import { SlPeople } from "react-icons/sl";
import { LuBadgePercent } from "react-icons/lu";
import { PiNotificationBold } from "react-icons/pi";
import { GrUserPolice } from "react-icons/gr";
import { TbCarouselHorizontal } from "react-icons/tb";
import { IoMdArrowDropright } from "react-icons/io";
import { MdDashboard } from "react-icons/md";
import { BsPeople } from "react-icons/bs";
import TopNavBar from "../TopNavBar/TopNavBar";
import { VscGraph } from "react-icons/vsc";
import { ImParagraphRight } from "react-icons/im";
import { TbReportSearch } from "react-icons/tb";
import { AiOutlineBars } from "react-icons/ai";
import { GoOrganization } from "react-icons/go";
import { useSelector } from "react-redux";
import { IoWalletOutline } from "react-icons/io5";
import { CgNotes } from "react-icons/cg";

function SideBarMenu() {
  const expand = "md";
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  const navigate = useNavigate();
  const location = useLocation();

  const empdpartment = useSelector(
    (state) => state?.user?.userInfo?.empDepartment
  );

  const [active, setActive] = useState("");

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  const handleClose = () => setShowOffcanvas(false);

  return (
    <>
      {/* <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3"> */}
      <Navbar key={expand} expand={expand}>
        <Container fluid>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${expand}`}
            onClick={() => setShowOffcanvas(true)}
          />
          {screenWidth > 599.95 ? null : (
            <Navbar.Brand>
              {/* <Image
                src={logo}
                alt="logo"
                className="sbm1"
                onClick={() => navigate("/")}
              /> */}
              <TopNavBar />
            </Navbar.Brand>
          )}

          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="start"
            show={showOffcanvas}
            // onHide={handleClose}
            className="sbm3"
          >
            <Offcanvas.Header>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <Image
                  src={logo}
                  alt="logo"
                  className="sbm2"
                  onClick={() => {
                    navigate("/");
                    handleClose();
                  }}
                />
              </Offcanvas.Title>
              <span>
                <MdClose
                  onClick={handleClose}
                  style={{
                    height: "1.2rem",
                    width: "1.2rem",
                  }}
                />
              </span>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Container className="sbm15">
                {screenWidth < 599.95 ? null : (
                  <Row className="sbm17">
                    <Col className="mmlogo">
                      <Image
                        src={mmlogo}
                        alt="logo"
                        className="sbm18"
                        onClick={() => navigate("/")}
                      />
                      <h5 className="jbsContainer">JBS Interior</h5>
                      <div className="panel-name">
                        {empdpartment === "procurement_and_inventory"
                          ? "Inventory"
                          : "Accounts"}
                      </div>
                    </Col>
                  </Row>
                )}

                {empdpartment === "procurement_and_inventory" ? (
                  <Row className="sbm14">
                    <Row
                      as={Link}
                      to="/dashboard"
                      onClick={() => {
                        handleClose();
                      }}
                      className={active === "/dashboard" ? "sbm6" : "sbm4"}
                    >
                      <Col
                        xxs={10}
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        xxl={10}
                        className="sbm9"
                      >
                        <span className="sbm5">
                          <MdDashboard
                            className={
                              active === "/dashboard" ? "sbm12" : "sbm11"
                            }
                            style={{ background: "black" }}
                          />
                        </span>
                        <span
                          className={
                            active === "/dashboard" ? "DashBoardActive" : ""
                          }
                        >
                          Dashboard
                        </span>
                      </Col>
                      <Col
                        xxs={2}
                        sm={2}
                        xs={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        className="sbm16"
                      >
                        <span>
                          {active === "/dashboard" ? (
                            <IoMdArrowDropright className="sbm7" />
                          ) : (
                            <MdArrowForwardIos className="sbm8" />
                          )}
                        </span>
                      </Col>
                    </Row>

                    <Row
                      as={Link}
                      to="/orderhistory"
                      onClick={() => {
                        handleClose();
                      }}
                      className={active === "/orderhistory" ? "sbm6" : "sbm4"}
                    >
                      <Col
                        xxs={10}
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        xxl={10}
                        className="sbm9"
                      >
                        <span className="sbm5">
                          <ImParagraphRight
                            className={
                              active === "/orderhistory" ? "sbm12" : "sbm11"
                            }
                          />
                        </span>
                        <span>Inventory</span>
                      </Col>
                      <Col
                        xxs={2}
                        sm={2}
                        xs={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        className="sbm16"
                      >
                        <span>
                          {active === "/orderhistory" ? (
                            <IoMdArrowDropright className="sbm7" />
                          ) : (
                            <MdArrowForwardIos className="sbm8" />
                          )}
                        </span>
                      </Col>
                    </Row>

                    <Row
                      as={Link}
                      to="/CheckMaterialRequest"
                      onClick={() => {
                        handleClose();
                      }}
                      className={
                        active === "/CheckMaterialRequest" ? "sbm6" : "sbm4"
                      }
                    >
                      <Col
                        xxs={10}
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        xxl={10}
                        className="sbm9"
                      >
                        <span className="sbm5">
                          <TbReportSearch
                            className={
                              active === "/CheckMaterialRequest"
                                ? "sbm12"
                                : "sbm11"
                            }
                          />
                        </span>
                        <span>Material Request</span>
                      </Col>
                      <Col
                        xxs={2}
                        sm={2}
                        xs={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        className="sbm16"
                      >
                        <span>
                          {active === "/CheckMaterialRequest" ? (
                            <IoMdArrowDropright className="sbm7" />
                          ) : (
                            <MdArrowForwardIos className="sbm8" />
                          )}
                        </span>
                      </Col>
                    </Row>
                  </Row>
                ) : (
                  <Row className="sbm14">
                    <Row
                      as={Link}
                      to="/AcDashboard"
                      onClick={() => {
                        handleClose();
                      }}
                      className={active === "/AcDashboard" ? "sbm6" : "sbm4"}
                    >
                      <Col
                        xxs={10}
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        xxl={10}
                        className="sbm9"
                      >
                        <span className="sbm5">
                          <MdDashboard
                            className={
                              active === "/AcDashboard" ? "sbm12" : "sbm11"
                            }
                          />
                        </span>
                        <span
                          className={
                            active === "/AcDashboard" ? "DashBoardActive" : ""
                          }
                        >
                          Dashboard
                        </span>
                      </Col>
                      <Col
                        xxs={2}
                        sm={2}
                        xs={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        className="sbm16"
                      >
                        <span>
                          {active === "/AcDashboard" ? (
                            <IoMdArrowDropright className="sbm7" />
                          ) : (
                            <MdArrowForwardIos className="sbm8" />
                          )}
                        </span>
                      </Col>
                    </Row>

                    <Row
                      as={Link}
                      to="/AcPayroll"
                      onClick={() => {
                        handleClose();
                      }}
                      className={active === "/AcPayroll" ? "sbm6" : "sbm4"}
                    >
                      <Col
                        xxs={10}
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        xxl={10}
                        className="sbm9"
                      >
                        <span className="sbm5">
                          <IoWalletOutline
                            className={
                              active === "/AcPayroll" ? "sbm12" : "sbm11"
                            }
                          />
                        </span>
                        <span
                          className={
                            active === "/AcPayroll" ? "payrollActive" : ""
                          }
                        >
                          Payroll
                        </span>
                      </Col>
                      <Col
                        xxs={2}
                        sm={2}
                        xs={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        className="sbm16"
                      >
                        <span>
                          {active === "/AcPayroll" ? (
                            <IoMdArrowDropright className="sbm7" />
                          ) : (
                            <MdArrowForwardIos className="sbm8" />
                          )}
                        </span>
                      </Col>
                    </Row>

                    <Row
                      as={Link}
                      to="/AcTransaction"
                      onClick={() => {
                        handleClose();
                      }}
                      className={active === "/AcTransaction" ? "sbm6" : "sbm4"}
                    >
                      <Col
                        xxs={10}
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        xxl={10}
                        className="sbm9"
                      >
                        <span className="sbm5">
                          <AiOutlineBars
                            className={
                              active === "/AcTransaction" ? "sbm12" : "sbm11"
                            }
                          />
                        </span>
                        <span
                          className={
                            active === "/AcTransaction"
                              ? "TransactionActive"
                              : ""
                          }
                        >
                          Transactions
                        </span>
                      </Col>
                      <Col
                        xxs={2}
                        sm={2}
                        xs={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        className="sbm16"
                      >
                        <span>
                          {active === "/AcTransaction" ? (
                            <IoMdArrowDropright className="sbm7" />
                          ) : (
                            <MdArrowForwardIos className="sbm8" />
                          )}
                        </span>
                      </Col>
                    </Row>
                    <Row
                      as={Link}
                      to="/AcOrderHistory"
                      onClick={() => {
                        handleClose();
                      }}
                      className={active === "/AcOrderHistory" ? "sbm6" : "sbm4"}
                    >
                      <Col
                        xxs={10}
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        xxl={10}
                        className="sbm9"
                      >
                        <span className="sbm5">
                          <TbReportSearch />
                        </span>
                        <span
                          className={
                            active === "/AcOrderHistory" ? "OrderHistory" : ""
                          }
                        >
                          Order History
                        </span>
                      </Col>
                      <Col
                        xxs={2}
                        sm={2}
                        xs={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        className="sbm16"
                      >
                        <span>
                          {active === "/AcOrderHistory" ? (
                            <IoMdArrowDropright className="sbm7" />
                          ) : (
                            <MdArrowForwardIos className="sbm8" />
                          )}
                        </span>
                      </Col>
                    </Row>
                    <Row
                      as={Link}
                      to="/AcBillReport"
                      onClick={() => {
                        handleClose();
                      }}
                      className={active === "/AcBillReport" ? "sbm6" : "sbm4"}
                    >
                      <Col
                        xxs={10}
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        xl={10}
                        xxl={10}
                        className="sbm9"
                      >
                        <span className="sbm5">
                          <CgNotes
                            className={
                              active === "/AcBillReport" ? "sbm12" : "sbm11"
                            }
                          />
                        </span>
                        <span
                          className={
                            active === "/AcBillReport" ? "billActive" : ""
                          }
                        >
                          Bill & Report
                        </span>
                      </Col>
                      <Col
                        xxs={2}
                        sm={2}
                        xs={2}
                        md={2}
                        lg={2}
                        xl={2}
                        xxl={2}
                        className="sbm16"
                      >
                        <span>
                          {active === "/AcBillReport" ? (
                            <IoMdArrowDropright className="sbm7" />
                          ) : (
                            <MdArrowForwardIos className="sbm8" />
                          )}
                        </span>
                      </Col>
                    </Row>
                  </Row>
                )}
              </Container>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default SideBarMenu;
