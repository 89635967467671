import React, { useState, useEffect } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { RxEyeOpen } from "react-icons/rx";
import mmicon from "../../assets/dashboard/logo.png";
import { FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { login } from "../../../Redux/Slice/userSlice";
import Utility from "../../../Utils/Utility";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";

import "./Login.css";

const Login = () => {
  const [spin, setSpin] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState("password");
  const [seePassword, setSeePassword] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const togglePassword = () => {
    setSeePassword(!seePassword);
    type === "password" ? setType("text") : setType("password");
  };

  const Validation = () => {
    if (email?.trim() === "") {
      Utility.eToast("Enter Email Id");
      return false;
    } else if (password?.trim() === "") {
      Utility.eToast("Enter Password");
      return false;
    }
    return true;
  };

  const handleFormValidation = () => {
    if (Validation()) {
      const data = { email, password, role: "employee" };
      setSpin(true);
      dispatch(login(data))
        .then(unwrapResult)
        .then((res) => {
          setSpin(false);
          console.log(">>>>>>>>>>>>>>>>>>>>>>>>", res);
          // Utility.sToast("Logged in successfully!");
          if (res?.data?.empDepartment === "procurement_and_inventory") {
            navigate("/dashboard");
          } else {
            navigate("/AcDashboard");
          }
        })
        .catch((err) => {
          // Utility.eToast("Wrong email or password");
          setSpin(false);
        });
    }
  };

  return (
    <Container
      fluid
      className="loginContainer d-flex justify-content-center align-items-center"
    >
      <div id="loginDiv">
        <div className="text-center">
          <img id="mmIcon" src={mmicon} alt="" />
        </div>
        <div className="text-center">
          <h1 id="loginHeading" style={{ color: "black" }}>
            Sub Admin Login
          </h1>
        </div>
        <div className="text-center">
          <p id="loginBelowText">
            {" "}
            Log in to manage system settings and user permissions
          </p>
        </div>

        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="label">Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Your Email Address"
              className="input"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group
            className="mb-3 position-relative"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="label">Password</Form.Label>
            <Form.Control
              type={type}
              placeholder="Enter Your Password"
              className="input"
              required
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {seePassword ? (
              <FaEyeSlash
                id="eyeCloseIcon"
                onClick={togglePassword}
                color="#595959"
              />
            ) : (
              <RxEyeOpen
                id="eyeOpenIcon"
                onClick={togglePassword}
                color="#595959"
              />
            )}
          </Form.Group>
          <Form.Group>
            <Button size="lg" id="loginBtn" onClick={handleFormValidation}>
              {spin ? <CircularIndeterminate /> : "Log In"}
            </Button>
          </Form.Group>
          <p className="Adminmessage" style={{ color: "black" }}>
            Unable to log in due to an issue; please contact the admin.
          </p>
        </Form>
      </div>
    </Container>
  );
};

export default Login;
