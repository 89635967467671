import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import "./Barchart.css";
import { useState } from "react";

// const dataset = [
//   {
//     seoul: 21,
//     month: "April 10",
//   },
//   {
//     seoul: 28,
//     month: "April 11",
//   },
//   {

//     seoul: 41,
//     month: "April 12",
//   },
//   {
//     seoul: 73,
//     month: "April 13",
//   },
//   {
//     seoul: 99,
//     month: "April 14",
//   },
//   {
//     seoul: 54,
//     month: "April 15",
//   },
//   {
//     seoul: 100,
//     month: "April 16",
//   },
// ];

const valueFormatter = (value) => {
  if (value >= 1e9) {
    return (value / 1e9).toFixed(1).replace(/\.0$/, "") + "b";
  }
  if (value >= 1e6) {
    return (value / 1e6).toFixed(1).replace(/\.0$/, "") + "m";
  }
  if (value >= 1e3) {
    return (value / 1e3).toFixed(1).replace(/\.0$/, "") + "k";
  }
  return value.toString() + "rs";
};

export default function TickPlacementBars({
  data,
  accessKey = "",
  widthOfBar = 0.8,
}) {
  const [tickPlacement, setTickPlacement] = useState("middle");
  const [tickLabelPlacement, setTickLabelPlacement] = useState("middle");
  const props = { data, accessKey, valueFormatter };
  console.log("Data from barCart", typeof props.accessKey);
  const dataset = props?.data;
  const key = props?.accessKey || "seoul";

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <BarChart
        grid={{ horizontal: true }}
        sx={{ paddingTop: "20px" }}
        dataset={dataset}
        borderRadius={15}
        series={[{ dataKey: key, color: ["#C76E00"], valueFormatter }]}
        height={400}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "month",
            tickPlacement,
            tickLabelPlacement,
            categoryGapRatio: widthOfBar,
          },
        ]}
        leftAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        bottomAxis={{
          disableLine: true,
          disableTicks: true,
        }}
      />
    </div>
  );
}
