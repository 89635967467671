import React from "react";
import { Container, Modal, Row, Col } from "react-bootstrap";
import "./Saleryform.css";
import Utility from "../../../Utils/Utility";
import GlobalButton from "./../../../globalcomponents/GlobalButton/GlobalButton";
import { UserController } from "../../../Redux/controllers/UserController";
import { useSelector } from "react-redux";

const Saleryform = (props) => {
  const data = props?.requestedData;
  const token = useSelector((state) => state?.user?.token);

  const handleApprove = async () => {
    try {
      const response = await UserController.approveRequestById({
        token,
        id: data?._id,
      });
      Utility.sToast(response?.data?.data?.message);
      props.setRefresh((prev) => !prev);
      props.onHide();
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <Modal {...props} size="lg">
      <Modal.Header closeButton style={{ background: "#E4CDAA" }}>
        <Modal.Title style={{ color: "#642315E5", background: "#E4CDAA" }}>
          Required Material Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Row className="mt-1">
            <Col>Order ID</Col>
            <Col className="value">#{data?.orderId?.orderID}</Col>
          </Row>
        </Row>
        <Row>
          <div id="orderHistory">
            <h6 style={{ color: "#642315" }}>Product Details</h6>
          </div>

          {data?.orderId?.productDetails?.map((product, index) => (
            <>
              <Row>
                <span>
                  <strong>Product {index + 1}</strong>
                </span>
                <hr />
              </Row>
              <Row className="mt-2">
                <Col>Product Name</Col>
                <Col className="value">{product?.product?.name || "--"}</Col>
              </Row>
              <Row className="mt-2">
                <Col>Product ID</Col>
                <Col className="value">
                  {product?.product?.productID || "--"}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>Product Price</Col>
                <Col className="value">
                  {Utility.MoneyFormatter(product?.product?.price)}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>Pictures</Col>
                <Col className="value">
                  {product?.product?.images?.map((item, index) => {
                    return (
                      <img
                        className="requested-product-image"
                        src={item}
                        alt={"image " + index}
                      />
                    );
                  }) || "--"}
                </Col>
              </Row>
            </>
          ))}
        </Row>

        {/* <div id="orderHistory">
          <h6 style={{ color: "#642315" }}>Product Details</h6>
        </div> */}
        {/* <Row className="mt-2">
          <Col>Product Name</Col>
          <Col className="value">
            {data?.orderId?.productDetails?.product?.name || "--"}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>Product ID</Col>
          <Col className="value">
            {data?.orderId?.productDetails?.product?.productID || "--"}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>Product Price</Col>
          <Col className="value">
            {Utility.MoneyFormatter(
              data?.orderId?.productDetails?.product?.price
            )}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>Pictures</Col>
          <Col className="value">
            {data?.orderId?.productDetails?.product?.images?.map(
              (item, index) => {
                return (
                  <img
                    className="requested-product-image"
                    src={item}
                    alt={"image " + index}
                  />
                );
              }
            ) || "--"}
          </Col>
        </Row> */}

        <div id="orderHistory">
          <h6 style={{ color: "#642315" }}>Material Details</h6>
        </div>
        <Row className="mt-2">
          <Col>Material Name</Col>
          <Col className="value">{data?.materialId?.name}</Col>
        </Row>
        <Row className="mt-2">
          <Col>Requested By</Col>
          <Col className="value">{data?.requestedBy?.name}</Col>
        </Row>
        <Row className="mt-2">
          <Col>Requested On</Col>
          <Col className="value">
            {Utility.formatDate(data?.requestedDate)}{" "}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>Size</Col>
          <Col className="value">{data?.materialId?.size || "--"} </Col>
        </Row>
        <Row className="mt-2">
          <Col>Color</Col>
          <Col className="pattern-img-container">
            {data?.materialId?.color ? (
              <div
                className="color-box-1"
                style={{ backgroundColor: `${data?.materialId?.color}` }}
              ></div>
            ) : (
              "--"
            )}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>Pattern</Col>
          <Col className="pattern-img-container">
            {data?.materialId?.pattern ? (
              <img
                src={data?.materialId?.pattern}
                className="pattern-box-1"
                alt="pattern"
              />
            ) : (
              "--"
            )}
          </Col>
        </Row>
        {data?.currentStatus === "pending" && (
          <>
            <Row className="mt-2">
              <Col>Required Quantity</Col>
              <Col className="value">
                {data?.requiredQuantity || "0"} {""}
                {data?.materialId?.unit}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>Available Quantity</Col>
              <Col className="value boldText">
                {data?.materialId?.stock || "0"} {""}
                {data?.materialId?.unit}
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <GlobalButton
                name={"Approve"}
                marginTop="1rem"
                background="#15B097"
                hoverbgcolor="#15B097"
                onclick={handleApprove}
              />
            </div>
          </>
        )}

        {data?.currentStatus !== "pending" && (
          <>
            <div id="orderHistory">
              <h6 style={{ color: "#642315" }}>Approval Details</h6>
            </div>
            <Row className="mt-2">
              <Col>Approved Quantity</Col>
              <Col className="value">
                {data?.requiredQuantity || "0"} {""}
                {data?.materialId?.unit}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>Approved By</Col>
              <Col className="value">{data?.approvedBy?.name || "--"}</Col>
            </Row>
            <Row className="mt-2">
              <Col>Approved On</Col>
              <Col className="value">
                {Utility.formatDate(data?.approvedDate) || "--"}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>Status</Col>
              <Col className="value boldText">
                {data?.currentStatus?.toUpperCase()}
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Saleryform;
