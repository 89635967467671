import React, { useEffect, useMemo, useState } from "react";
import DataTable from "../../../globalcomponents/DataTable/DataTable.js";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton.js";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "./CheckMaterilaRequest.css";
import Saleryform from "../viewsaleryform/Saleryform.js";
import { useDispatch, useSelector } from "react-redux";
import { UserController } from "../../../Redux/controllers/UserController.js";
import { unwrapResult } from "@reduxjs/toolkit";
import Utility from "../../../Utils/Utility.js";
import Form from "react-bootstrap/Form";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner.js";
import { logOut } from "../../../Redux/Slice/userSlice.js";

const CheckMaterialRequest = () => {
  const dispatch = useDispatch();
  const [allRequests, setAllRequest] = useState([]);
  const navigate = useNavigate();
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [saleryState, setSaleryState] = useState(false);
  const [filter, setFilter] = useState("");
  const token = useSelector((state) => state.user.token);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const onclose = () => {
    setSaleryState(false);
  };

  const handleView = (item) => {
    setSaleryState(true);
    setSelectedRequest(item);
  };

  useEffect(() => {
    getAllRequestDetails();
  }, [refresh]);

  const getAllRequestDetails = async () => {
    try {
      setLoading(true);
      const response = await UserController.getAllRequestDetailsAPI(token);
      const res = unwrapResult(response);
      setAllRequest(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Utility.eToast(error?.response?.data?.message || error?.message);
      if (
        error?.response?.data?.message ===
        "token has expired , please login again"
      ) {
        dispatch(logOut());
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "slno",
        header: "Sl No.",
        size: 100,
      },
      {
        accessorKey: "material",
        header: "Material",
        size: 100,
      },
      {
        accessorKey: "requestedBy",
        header: "Requested By",
        size: 100,
      },
      {
        accessorKey: "requestedDate",
        header: "Request Date",
        size: 100,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 200,
      },
      {
        accessorKey: "action",
        header: "Action",
        size: 100,
      },
    ],
    []
  );

  const filteredData = allRequests.filter((item) => {
    if (filter === "approved") {
      return item.currentStatus === "approved";
    } else if (filter === "pending") {
      return item.currentStatus === "pending";
    } else {
      return true; // no filter, show all
    }
  });

  const data = filteredData.map((item, index) => {
    return {
      slno: index + 1,
      material: item?.materialId?.name,
      requestedBy: item?.requestedBy?.name,
      requestedDate: Utility.formatDate(item?.requestedDate),
      status: (
        <button
          disabled
          style={{
            backgroundColor: `${
              item?.currentStatus === "pending" ? "#EDA145" : "#15B097"
            }`,
          }}
          className="material-status-btn"
        >
          {item?.currentStatus === "pending" ? "Pending" : "Approved"}
        </button>
      ),
      action: (
        <GlobalButton
          name={"View"}
          background={"#E4CDAA"}
          color={"#642315"}
          padding={"0"}
          onclick={() => handleView(item)}
        />
      ),
    };
  });

  return (
    <>
      <Saleryform
        requestedData={selectedRequest}
        show={saleryState}
        onHide={onclose}
        setRefresh={setRefresh}
      />

      <div className="mt-3 emptapblepaddding">
        <Row className="mt-2">
          <Col>
            <h5 style={{ color: "#642315" }}>Material Request</h5>
          </Col>
        </Row>
        <Row className="filterbutton">
          <Col sm={6}>
            <Form.Select
              className="StockSelect mx-2"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="">Select Filter</option>
              <option value="approved">Approved</option>
              <option value="pending">Pending</option>
            </Form.Select>
          </Col>
        </Row>
        <Row className="tablepaddinginner">
          {!loading ? (
            <DataTable columns={columns} data={data} />
          ) : (
            <div className=" Loader">
              <CircularIndeterminate />
            </div>
          )}
        </Row>
      </div>
    </>
  );
};

export default CheckMaterialRequest;
