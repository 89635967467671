import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { FaPlus } from "react-icons/fa";
import AddProductModal from "../Modals/AddProduct/AddProductModal";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import "./BillGenerateInvoice.css";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import { MdDelete } from "react-icons/md";
import Utility from "../../../Utils/Utility";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";
import { AccountsController } from "../../../Redux/controllers/AccountsController";
import { useSelector } from "react-redux";
import { UserController } from "../../../Redux/controllers/UserController";
import BillProductForm from "../Modals/BillProductForm/BillProductForm";
import { NumericFormat } from "react-number-format";

export default function BillGenerateInvoice(props) {
  const [addModal, setModal] = useState(false);

  const [BalanceAfterDelivary, setBalanceAfterDelivary] = useState("");

  const [transaction, setTransaction] = useState({
    trID: "",
    trPaymentID: "",
    trType: "sales",
    trDescription: "",
    trAdditionalNotes: "",
    trPaymentMethod: "",
    trsProductDetails: [],
    trsTotalAmount: "",
    trsShippingFee: "",
    trsDiscount: "",
    trsFinalAmount: "",
    trsCustomerName: "",
    trsCustomerPhoneNo: "",
    trsPaymentDate: "",
    trsBillingAddress: "",
    trsShippingAddress: "",
    trBalance: "",
    trDoc: "",
    trDeposite: "",
    PaidBeforeDelivery: "",
    paidAfterDelivery: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    trsCustomerName: "",
    trsCustomerPhoneNo: "",
    trsBillingAddress: "",
    trsShippingAddress: "",
    trPaymentMethod: "",
    PaidBeforeDelivery: "",
    BalanceAfterDelivary: "",
  });
  const navigate = useNavigate();
  const { token } = useSelector((st) => st?.user);

  const handleChange = (e) => {
    if (e.target) {
      setTransaction((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      setErrors((prev) => ({ ...prev, [e.target.name]: "" }));
    }
  };
  const handleFileChange = async (e) => {
    const filedata = e.target.files[0];
    if (!filedata) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("doc", filedata);
      const { data } = await UserController.SingleImageUploader(formData);
      setTransaction((prevDetails) => ({
        ...prevDetails,
        trDoc: data?.doc,
      }));
      Utility.sToast(data?.message);
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    }
  };

  function calculateTotalAmount() {
    if (transaction?.trsProductDetails?.length === 0) {
      setTransaction((prev) => ({
        ...prev,
        trsShippingFee: "",
        trsDiscount: "",
      }));
    }

    if (transaction.trsFinalAmount && transaction.PaidBeforeDelivery) {
      setBalanceAfterDelivary(
        parseFloat(transaction.trsFinalAmount) -
          parseFloat(transaction.PaidBeforeDelivery)
      );
    }

    let totalGrossAmount = transaction?.trsProductDetails?.reduce(
      (acc, item) => acc + item?.trsGrossAmount || 0,
      0
    );
    let finalAmount =
      totalGrossAmount +
      (+transaction?.trsShippingFee || 0) -
      (+transaction?.trsDiscount || 0);
    setTransaction((prev) => ({
      ...prev,
      trsTotalAmount: totalGrossAmount || 0,
      trsFinalAmount: finalAmount || 0,
    }));
  }

  const handleSaveProduct = (product) => {
    setTransaction({
      ...transaction,
      trsProductDetails: [...transaction?.trsProductDetails, product],
    });
  };

  const handleDeleteProduct = (productID) => {
    let newList = transaction?.trsProductDetails?.filter(
      (product) => product?.trsProductID !== productID
    );
    setTransaction((prev) => ({
      ...prev,
      trsProductDetails: newList,
    }));
    calculateTotalAmount();
  };

  const handleNewChange = (e) => {
    const { name, value } = e.target;
    setTransaction((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({ ...prev, [e.target.name]: "" }));
  };

  const validateForm = () => {
    const newErrors = {
      trsCustomerName: transaction.trsCustomerName
        ? ""
        : "Customer name is required",
      trsCustomerPhoneNo: !transaction.trsCustomerPhoneNo
        ? "Customer phone number is required"
        : transaction.trsCustomerPhoneNo.length !== 10
        ? "Mobile Number should be 10 digits"
        : " ",
      trsBillingAddress: transaction.trsBillingAddress
        ? ""
        : "Billing address is required",
      trsShippingAddress: transaction.trsShippingAddress
        ? ""
        : "Shipping address is required",
      trPaymentMethod: transaction.trPaymentMethod
        ? ""
        : "Payment method is required",
      PaidBeforeDelivery: transaction.PaidBeforeDelivery
        ? ""
        : "Please provide deposited amount",
    };
    setErrors(newErrors);
    return Object.values(newErrors).some((item) => item === "");
  };
  const handleSubmit = async () => {
    const DataToBeSend = {
      invoiceDate: Utility.formatDate(Date.now()),
      billName: transaction?.trsCustomerName,
      shipName: transaction?.trsCustomerName,
      billAddress: transaction?.trsBillingAddress,
      shipAddress: transaction?.trsBillingAddress,
      billPhoneNo: transaction?.trsCustomerPhoneNo,
      shipPhoneNo: transaction?.trsCustomerPhoneNo,
      price: transaction?.trsFinalAmount,
      productList: transaction?.trsProductDetails?.map((item) => ({
        itemName: item?.trsProductName,
        itemId: item?.trsProductID,
        itemQuantity: item?.trsQuantity,
        itemGST: item?.trsGST,
        itemPrice: item?.trsGrossAmount,
        itemGrossAmount: item?.trsBaseAmount,
      })),
      totalPrice: transaction?.trsFinalAmount,
      subTotal: transaction?.trsTotalAmount,
      shippingCharges: transaction?.trsShippingFee,
      paidBeforeDelivery: transaction?.PaidBeforeDelivery,
      paidAfterDelivery: BalanceAfterDelivary,
      discount: transaction?.trsDiscount,
    };

    console.log("Here is the final Data", DataToBeSend);

    try {
      console.log(errors);
      if (!validateForm()) {
        console.log(errors);
        return;
      }

      if (transaction?.trsProductDetails?.length === 0) {
        return Utility?.eToast("Please select a product");
      }

      setLoading(true);

      let finalData = {
        ...transaction,
        trsShippingFee: +transaction?.trsShippingFee,
        trsDiscount: +transaction?.trsDiscount,
      };

      const response = await AccountsController.GenerateBillreportInvoice(
        DataToBeSend
      );
      await window.open(response?.data?.data);
      navigate(-1);
    } catch (error) {
      Utility?.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  const column = [
    {
      accessorKey: "productID",
      header: "Product ID",
      size: 50,
    },
    {
      accessorKey: "productNam",
      header: "Full Name",
      size: 150,
    },
    {
      accessorKey: "basePrice",
      header: "Base Price",
      size: 50,
    },
    {
      accessorKey: "quantity",
      header: "Qty",
      size: 50,
    },
    {
      accessorKey: "gst",
      header: "GST",
      size: 50,
    },
    {
      accessorKey: "grossAmount",
      header: "Gross Amount",
      size: 50,
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 50,
    },
  ];

  const data = transaction?.trsProductDetails?.map((item, i) => ({
    productID: item?.trsProductID || "N/A",
    productNam: item?.trsProductName || "N/A",
    basePrice: (item?.trsBaseAmount && "₹ ") + item?.trsBaseAmount || "N/A",
    quantity: item?.trsQuantity || "N/A",
    gst: (item?.trsGST && item?.trsGST + " %") || "N/A",
    grossAmount: (item?.trsGrossAmount && "₹ ") + item?.trsGrossAmount || "N/A",
    action: (
      <MdDelete
        className="md-delete"
        onClick={() => handleDeleteProduct(item?.trsProductID)}
        style={{ color: "#dc2329" }}
      />
    ),
  }));

  useEffect(() => {
    calculateTotalAmount();
  }, [
    transaction?.trsProductDetails,
    transaction?.trsShippingFee,
    transaction?.trsDiscount,
    transaction?.trsTotalAmount,
    transaction?.PaidBeforeDelivery,
  ]);

  return (
    <>
      {addModal && (
        <BillProductForm
          selectedProductList={transaction?.trsProductDetails}
          onHide={() => setModal(false)}
          show={addModal}
          onSaveProduct={handleSaveProduct}
          calculateTotalAmount={calculateTotalAmount}
        />
      )}
      <Container className="BillGenerateInvoicecontainer">
        <Row className="saletop">
          <Col className="stext" style={{ color: "black" }}>
            Invoice Details
          </Col>
          <Col className="crossofform" onClick={() => navigate(-1)}>
            <RxCross1 id="crossicon" />
          </Col>
        </Row>
        <Row id="subadminmodalbody">
          <Col>
            <Form>
              <Form.Label
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "black",
                }}
              >
                Product Details
              </Form.Label>

              {transaction?.trsProductDetails?.length > 0 && (
                <DataTable
                  columns={column}
                  data={data}
                  enableGlobalFilter={false}
                  enableBottomToolbar={false}
                  enablePagination={false}
                />
              )}

              {errors.trsProductDetails && (
                <p className="text-danger">{errors.trsProductDetails}</p>
              )}

              <Row className="addformcol">
                <div style={{ width: "fit-content" }}>
                  <GlobalButton
                    name={"Add Product Details"}
                    background="#dc2329"
                    color="#fff"
                    onclick={() => {
                      setModal(true);
                    }}
                    icon={<FaPlus />}
                  />
                </div>
              </Row>

              <Row id="ADNsubadminRow">
                <Col xs={12} md={12}>
                  <Form.Label id="ADNsubadminForm">Total Amount</Form.Label>
                  <Form.Control
                    disabled
                    type="number"
                    placeholder="Calculate total amount"
                    id="subadminFormcontrol"
                    name="trsTotalAmount"
                    onChange={handleChange}
                    value={transaction?.trsTotalAmount}
                  />
                </Col>
              </Row>

              <Row id="ADNsubadminRow">
                <Form.Label
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Bills Breakdown
                </Form.Label>

                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Shipping Fee</Form.Label>
                  <Form.Control
                    disabled={!transaction?.trsTotalAmount}
                    type="number"
                    placeholder="Enter Shipping Fee"
                    id="subadminFormcontrol"
                    name="trsShippingFee"
                    onChange={handleChange}
                    value={transaction?.trsShippingFee}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Discounts</Form.Label>
                  <Form.Control
                    disabled={!transaction?.trsTotalAmount}
                    type="number"
                    placeholder="Enter Discount amount"
                    id="subadminFormcontrol"
                    name="trsDiscount"
                    onChange={handleChange}
                    value={transaction?.trsDiscount}
                  />
                </Col>
              </Row>

              <Row id="ADNsubadminRow">
                <Col xs={12} md={12}>
                  <Form.Label id="ADNsubadminForm">
                    Final Transaction Amount
                  </Form.Label>
                  <Form.Control
                    disabled
                    placeholder="Enter Total Amount"
                    id="subadminFormcontrol"
                    name="trsFinalAmount"
                    onChange={handleChange}
                    value={transaction?.trsFinalAmount}
                  />
                </Col>
              </Row>

              <Form.Label
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "black",
                }}
              >
                Payment Details
              </Form.Label>

              <Row id="subadminmodalbody1">
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Payment Method</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="trPaymentMethod"
                    onChange={handleChange}
                    value={transaction?.trPaymentMethod}
                    isInvalid={!!errors.trPaymentMethod}
                  >
                    <option>Choose Payment Method</option>
                    <option value="bank_transfer_or_upi">
                      Upi/Bank Transfer
                    </option>
                    <option value="cash">Cash</option>
                    <option value="check">Check</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.trPaymentMethod}
                  </Form.Control.Feedback>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">
                    Transaction Reference/ID (if any)
                  </Form.Label>
                  <br />
                  <Form.Control
                    type="text"
                    id="subadminFormcontrol"
                    className="custom-date-input"
                    placeholder="Enter Payment Transaction ID"
                    name="trPaymentID"
                    onChange={handleChange}
                    value={transaction?.trPaymentID}
                  />
                </Col>
              </Row>
              <Form.Label
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "black",
                }}
              >
                Customer Details
              </Form.Label>

              <Row id="subadminmodalbody1">
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Name Of Customer</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name of customer"
                    id="subadminFormcontrol"
                    name="trsCustomerName"
                    onChange={handleChange}
                    value={transaction?.trsCustomerName}
                    isInvalid={!!errors.trsCustomerName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.trsCustomerName}
                  </Form.Control.Feedback>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Contact Number</Form.Label>
                  <br />
                  <Form.Control
                    type="number"
                    id="subadminFormcontrol"
                    className="custom-date-input"
                    placeholder="Enter Contact Number"
                    name="trsCustomerPhoneNo"
                    onChange={handleChange}
                    value={transaction?.trsCustomerPhoneNo}
                    isInvalid={!!errors.trsCustomerPhoneNo}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.trsCustomerPhoneNo}
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row id="ADNsubadminRow">
                <Col xs={12} md={12}>
                  <Form.Label id="ADNsubadminForm">Billing Address</Form.Label>
                  <Form.Control
                    placeholder="Customer Billing Address"
                    id="subadminFormcontrol"
                    name="trsBillingAddress"
                    onChange={handleChange}
                    value={transaction?.trsBillingAddress}
                    isInvalid={!!errors.trsBillingAddress}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.trsBillingAddress}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row id="ADNsubadminRow">
                <Col xs={12} md={12}>
                  <Form.Label id="ADNsubadminForm">Shipping Address</Form.Label>
                  <Form.Control
                    placeholder="Customer Shipping Address"
                    id="subadminFormcontrol"
                    name="trsShippingAddress"
                    onChange={handleChange}
                    value={transaction?.trsShippingAddress}
                    isInvalid={!!errors.trsShippingAddress}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.trsShippingAddress}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row id="ADNsubadminRow">
                <Col xs={12} md={12}>
                  <Form.Label id="ADNsubadminForm">
                    ₹ Deposit Paid (Before Delivery)
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter deposited amount"
                    id="subadminFormcontrol"
                    name="PaidBeforeDelivery"
                    onChange={(e) => {
                      handleNewChange(e);
                      let val =
                        parseFloat(transaction?.trsFinalAmount) -
                        parseFloat(e.target.value);
                      setErrors((prev) => ({
                        ...prev,
                        BalanceAfterDelivary:
                          val < 0 ? "Can't be negetive" : "",
                      }));
                    }}
                    value={transaction?.PaidBeforeDelivery}
                    isInvalid={!!errors.PaidBeforeDelivery}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.PaidBeforeDelivery}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row id="ADNsubadminRow">
                <Col xs={12} md={12}>
                  <Form.Label id="ADNsubadminForm">
                    ₹ Balance Due (After Delivery)
                  </Form.Label>
                  <Form.Control
                    disabled
                    type="number"
                    placeholder="Enter remaning amount"
                    id="subadminFormcontrol"
                    name="paidAfterDelivery"
                    value={BalanceAfterDelivary}
                    isInvalid={!!errors.BalanceAfterDelivary}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.BalanceAfterDelivary}
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row className="sallastend">
                <div style={{ width: "fit-content" }}>
                  {loading ? (
                    <CircularIndeterminate />
                  ) : (
                    <GlobalButton
                      name="Submit"
                      background="#dc2329"
                      color="#fff"
                      onclick={handleSubmit}
                    />
                  )}
                </div>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
