import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Container, Form } from "react-bootstrap";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import "./BIllProductForm.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AccountsController } from "../../../../Redux/controllers/AccountsController";

const BillProductForm = ({
  onHide,
  show,
  onSaveProduct,
  calculateTotalAmount,
  selectedProductList,
}) => {
  const navigate = useNavigate();
  const token = useSelector((st) => st?.user?.token);
  const [productList, setProductList] = useState([]);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});

  const getProductList = async () => {
    try {
      const { data } = await AccountsController?.getProductListAPI(token);
      setProductList(data?.data);
    } catch (error) {}
  };

  const calculateGrossAmount = (basePrice, quantity = 1, gstRate = 0) => {
    let overAllPrice = basePrice * quantity;
    let gstAmount = (overAllPrice * gstRate) / 100;
    let grossAmount = Math.round(overAllPrice + gstAmount);
    setProductDetails((prev) => ({ ...prev, trsGrossAmount: grossAmount }));
  };

  const [productDetails, setProductDetails] = useState({
    trsProductRef: "",
    trsProductName: "",
    trsHsnCode: "",
    trsBaseAmount: "",
    trsQuantity: 1,
    trsGST: 0,
    trsGrossAmount: "",
  });

  useEffect(() => {
    getProductList();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductDetails((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!productDetails.trsProductName) {
      newErrors.trsProductName = "Product is required";
    }
    if (!productDetails.trsBaseAmount) {
      newErrors.trsBaseAmount = "Base amount is required";
    }
    if (!productDetails.trsQuantity) {
      newErrors.trsQuantity = "Quantity is required";
    }
    if (!productDetails.trsGST) {
      newErrors.trsGST = "GST is required";
    }

    if (Object.keys(newErrors).length === 0) {
      onSaveProduct({
        ...productDetails,
      });
      calculateTotalAmount();
      onHide();
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <Container className="p-2">
      <Modal id="transactionModal" show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton style={{ background: "#f4f3f0" }}>
          <Modal.Title style={{ color: "black", background: "#f4f3f0" }}>
            Provide Product Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Row id="subadminmodalbody1">
              <Col xs={12} md={6}>
                <Form.Label id="ADNsubadminForm">Product</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="trsProductName"
                  isInvalid={!!errors.trsProductName}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value !== "default") {
                      const product = JSON.parse(value);
                      const updatedDetails = {
                        trsProductRef: product?._id,
                        trsProductName: product?.name,
                        trsHsnCode: product?.hsnCode,
                        trsBaseAmount: product?.basePrice,
                        trsGST: product?.gst,
                        trsQuantity: 1,
                      };
                      setProductDetails((prev) => ({
                        ...prev,
                        ...updatedDetails,
                      }));
                      calculateGrossAmount(product?.basePrice, 1, product?.gst);
                    } else {
                      setProductDetails({
                        trsProductRef: "",
                        trsProductName: "",
                        trsHsnCode: "",
                        trsBaseAmount: "",
                        trsQuantity: 1,
                        trsGST: 0,
                        trsGrossAmount: "",
                      });
                    }
                  }}
                >
                  <option value="default">Select a Product</option>
                  {productList
                    ?.filter(
                      (product) =>
                        !selectedProductList?.some(
                          (item) => item.trsProductRef === product?._id
                        )
                    )
                    ?.map((item) => (
                      <option value={JSON.stringify(item)} key={item?._id}>
                        {item?.name}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.trsProductName}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={6}>
                <Form.Label id="ADNsubadminForm">HSN Code </Form.Label>
                <br />
                <Form.Control
                  disabled
                  type="text"
                  id="subadminFormcontrol"
                  className="custom-date-input"
                  placeholder="Enter Product HDN Code"
                  name="trsHsnCode"
                  value={productDetails?.trsHsnCode}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            <Row id="subadminmodalbody1">
              <Col xs={12} md={6}>
                <Form.Label id="ADNsubadminForm">Base Amount</Form.Label>
                <Form.Control
                  disabled={!productDetails?.trsProductName}
                  type="number"
                  placeholder="Enter Price for Product"
                  id="subadminFormcontrol"
                  name="trsBaseAmount"
                  value={productDetails?.trsBaseAmount}
                  isInvalid={!!errors.trsBaseAmount}
                  onChange={(e) => {
                    handleInputChange(e);
                    productDetails?.trsGST &&
                      calculateGrossAmount(
                        +e.target.value,
                        productDetails?.trsQuantity,
                        productDetails?.trsGST
                      );
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.trsBaseAmount}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={6}>
                <Form.Label id="ADNsubadminForm">Quantity</Form.Label>
                <br />
                <Form.Control
                  disabled={!productDetails?.trsProductName}
                  type="number"
                  id="subadminFormcontrol"
                  className="custom-date-input"
                  placeholder="Enter the Quantity"
                  name="trsQuantity"
                  value={productDetails?.trsQuantity}
                  isInvalid={!!errors.trsQuantity}
                  onChange={(e) => {
                    handleInputChange(e);
                    productDetails?.trsGST &&
                      calculateGrossAmount(
                        productDetails?.trsBaseAmount,
                        +e.target.value,
                        productDetails?.trsGST
                      );
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.trsQuantity}
                </Form.Control.Feedback>
              </Col>
            </Row>

            <Row id="subadminmodalbody1">
              <Col xs={12} md={6}>
                <Form.Label id="ADNsubadminForm">GST ( in %age )</Form.Label>
                <Form.Control
                  disabled
                  type="number"
                  id="subadminFormcontrol"
                  className="custom-date-input"
                  placeholder="Enter the GST"
                  name="trsGST"
                  value={productDetails?.trsGST}
                  isInvalid={!!errors.trsGST}
                  onChange={(e) => {
                    handleInputChange(e);
                    productDetails?.trsGST &&
                      calculateGrossAmount(
                        productDetails?.trsBaseAmount,
                        +e.target.value,
                        productDetails?.trsGST
                      );
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.trsGST}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} md={6}>
                <Form.Label id="ADNsubadminForm">Gross Amount</Form.Label>
                <br />
                <Form.Control
                  disabled
                  type="number"
                  id="subadminFormcontrol"
                  className="custom-date-input"
                  placeholder="Amount including Tax"
                  name="trsGrossAmount"
                  value={productDetails?.trsGrossAmount}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            <Row className="firstmodalcol">
              <div style={{ width: "fit-content" }}>
                <GlobalButton
                  name={"Save"}
                  background="#dc2329"
                  color="#fff"
                  onclick={handleFormSubmit}
                />
              </div>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default BillProductForm;
