import React, { useEffect, useState } from "react";
import "./AcDashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsCartCheckFill } from "react-icons/bs";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { BarChart } from "@mui/x-charts/BarChart";
import TickPlacementBars from "../../../globalcomponents/Barchart/DashboaedChart";
import Card from "../../../globalcomponents/Admincardslice/Card";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { PieChart } from "@mui/x-charts/PieChart";
import { RiIdCardLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserController } from "../../../Redux/controllers/UserController";
import Utility from "../../../Utils/Utility";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";
import { logOut } from "../../../Redux/Slice/userSlice";

// const getCurrentMonth = () => {
//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];
//   const now = new Date();
//   return monthNames[now?.getMonth()];
// };

export default function DashBoard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [statisticsData, setStatisticsData] = useState({});
  const [staticsLoading, setStaticsLoading] = useState(false);
  const [financeData, setFinanceData] = useState([]);

  const token = useSelector((state) => state.user.token);

  const getDashboardStatistics = async () => {
    try {
      setStaticsLoading(true);
      const resp = await UserController.getDashboardStatistics(token);
      setStatisticsData(resp?.data?.data);
      setStaticsLoading(false);
    } catch (error) {
      setStaticsLoading(false);
      Utility.eToast(error?.response?.data?.message || error?.message);
      if (
        error?.response?.data?.message ===
        "token has expired , please login again"
      ) {
        dispatch(logOut());
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setStaticsLoading(false);
    }
  };

  const getFinancialChartDetails = async () => {
    try {
      setStaticsLoading(true);
      const resp = await UserController.financialChartDetails(token);
      setFinanceData(resp?.data?.data);
      setStaticsLoading(false);
    } catch (error) {
      setStaticsLoading(false);
      console.log("Error", error);
    } finally {
      setStaticsLoading(false);
    }
  };

  useEffect(() => {
    getDashboardStatistics();
  }, []);

  useEffect(() => {
    getFinancialChartDetails();
  }, []);

  const [salesTimePeriod, setSalesTimePeriod] = useState("6-month");
  const [expensesTimePeriod, setExpensesTimePeriod] = useState("6-month");
  const [salaryTimePeriod, setSalaryTimePeriod] = useState("6-month");

  const shortenMonthValues = (data) => {
    return data.map((item) => {
      const [month, year] = item.month.split(" ");
      const shortMonth = month.slice(0, 3);
      const shortYear = year.slice(-2);
      return {
        ...item,
        month: `${shortMonth} ${shortYear}`,
      };
    });
  };

  const dataset = shortenMonthValues(financeData.slice(6, 12));

  let dataset1;
  let dataset2;
  let dataset3;

  if (salesTimePeriod === "6-month") {
    dataset1 = shortenMonthValues(financeData?.slice(6, 12));
  } else if (salesTimePeriod === "year") {
    dataset1 = shortenMonthValues(financeData);
  }
  if (expensesTimePeriod === "6-month") {
    dataset2 = shortenMonthValues(financeData?.slice(6, 12));
  } else if (expensesTimePeriod === "year") {
    dataset2 = shortenMonthValues(financeData);
  }
  if (salaryTimePeriod === "6-month") {
    dataset3 = shortenMonthValues(financeData?.slice(6, 12));
  } else if (salaryTimePeriod === "year") {
    dataset3 = shortenMonthValues(financeData);
  }

  console.log("financeData", financeData);

  const valueFormatter = Utility.valueFormatter;

  return (
    <Container className="dashboardmaincontainer">
      {/* cards */}
      <Row className="mainrow" style={{ display: "flex", gap: "10px" }}>
        <Col className="cardCol">
          <Card
            // navigateTo={"/AcTransaction"}
            name={"Total Sales"}
            icon={<BsCartCheckFill />}
            ordernumber={valueFormatter(
              statisticsData?.financialStats?.totalSales
            )}
            customernumber={[
              valueFormatter(statisticsData?.financialStats?.weekly[0]),
              valueFormatter(statisticsData?.financialStats?.monthly[0]),
              valueFormatter(statisticsData?.financialStats?.yearly[0]),
            ]}
          />
        </Col>
        <Col className="cardCol">
          <Card
            name={"Total Expenses"}
            icon={<BsFillPersonLinesFill />}
            ordernumber={valueFormatter(
              statisticsData?.financialStats?.totalExpenses
            )}
            customernumber={[
              valueFormatter(statisticsData?.financialStats?.weekly[1]),
              valueFormatter(statisticsData?.financialStats?.monthly[1]),
              valueFormatter(statisticsData?.financialStats?.yearly[1]),
            ]}
          />
        </Col>
        <Col className="cardCol">
          <Card
            name={"Total Salary"}
            icon={<RiIdCardLine />}
            ordernumber={valueFormatter(
              statisticsData?.financialStats?.totalSalary
            )}
            customernumber={[
              valueFormatter(statisticsData?.financialStats?.weekly[2]),
              valueFormatter(statisticsData?.financialStats?.monthly[2]),
              valueFormatter(statisticsData?.financialStats?.yearly[2]),
            ]}
          />
        </Col>
      </Row>
      {/* graphs */}
      <Row style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
        <Col className="firstgraph">
          <div className="graphTitle">
            <div>
              <p style={{ color: "black" }}>Financial Charts</p>
            </div>
            <div>
              <p>This Month</p>
            </div>
          </div>
          <PieChart
            series={[
              {
                data: [
                  { color: "#fefefe" },
                  {
                    id: 0,
                    value: statisticsData?.financialStats?.monthly[1],
                    label: `Expenses (${Utility.valueFormatter(
                      statisticsData?.financialStats?.monthly[1]
                    )})`,
                    color: "#FACC15",
                  },
                  {
                    id: 1,
                    value: statisticsData?.financialStats?.monthly[0],
                    label: `Sales (${Utility.valueFormatter(
                      statisticsData?.financialStats?.monthly[0]
                    )})`,
                    color: "#F59E0B",
                  },
                  {
                    id: 2,
                    value: statisticsData?.financialStats?.monthly[2],
                    label: `Salary (${Utility.valueFormatter(
                      statisticsData?.financialStats?.monthly[2]
                    )})`,
                    color: "#EC4899",
                  },
                ],
              },
            ]}
            height={200}
            margin={{ left: -35 }}
          />
        </Col>

        <Col className="firstgraph">
          <div className="graphTitle">
            <div>
              <p style={{ color: "black" }}>Sales</p>
            </div>
            <div>
              <select
                name="period"
                id="period"
                data-placeholder="This Week"
                className="cardselect"
                value={salesTimePeriod}
                onChange={(e) => setSalesTimePeriod(e.target.value)}
              >
                <option value="6-month">Last 6 Months</option>
                <option value="year">Last Year</option>
              </select>
            </div>
          </div>
          <TickPlacementBars
            data={dataset1}
            accessKey="totalSales"
            widthOfBar={0.85}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
        <Col className="firstgraph">
          <div className="graphTitle">
            <div>
              <p style={{ color: "black" }}>Expenses</p>
            </div>
            <div>
              <select
                name="period"
                id="period"
                data-placeholder="This week"
                className="cardselect"
                value={expensesTimePeriod}
                onChange={(e) => setExpensesTimePeriod(e.target.value)}
              >
                <option value="6-month">Last 6 month</option>
                <option value="year">Last year</option>
              </select>
            </div>
          </div>
          <BarChart
            borderRadius={8}
            dataset={dataset}
            yAxis={[
              {
                scaleType: "band",
                dataKey: "month",
                categoryGapRatio: 0.8,
              },
            ]}
            series={[
              {
                dataKey: "totalExpenses",
                valueFormatter,
                color: "#C76E00", // Set the bar color to #C76E00
              },
            ]}
            layout="horizontal"
            grid={{ vertical: true }}
            height={400}
          />
        </Col>

        <Col className="firstgraph">
          <div className="graphTitle">
            <div>
              <p style={{ color: "black" }}>Salary</p>
            </div>
            <select
              name="period"
              id="period"
              data-placeholder="This week"
              className="cardselect"
              value={salaryTimePeriod}
              onChange={(e) => setSalaryTimePeriod(e.target.value)}
            >
              <option value="6-month">Last 6 month</option>
              <option value="year">Last year</option>
            </select>
          </div>
          <TickPlacementBars
            data={dataset3}
            accessKey="totalSalary"
            widthOfBar={0.85}
          />
        </Col>
      </Row>
    </Container>
  );
}
