import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { IoFilter } from "react-icons/io5";
import { GoPlusCircle } from "react-icons/go";
import "./SalesData.css";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import ViewSalesData from "../ViewSalesData/ViewSalesData";
import { useNavigate } from "react-router-dom";
import SaleForm from "../SaleAddForm/SaleForm";
import { AccountsController } from "./../../../Redux/controllers/AccountsController";
import { useSelector } from "react-redux";
import Utility from "./../../../Utils/Utility";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";

const SalesData = () => {
  const navigate = useNavigate();
  const { token } = useSelector((st) => st?.user);
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAllTransactionData = async () => {
    try {
      setLoading(true);
      const { data } = await AccountsController.getAllTransactionDetailsAPI(
        "sales",
        token
      );
      setSalesData(data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllTransactionData();
  }, []);
  const handelView = (item) => {
    navigate("/ViewSalesData", { state: item });
  };

  const handleSaleForm = () => {
    navigate("/SaleForm");
  };
  const columns = [
    {
      accessorKey: "slno",
      header: "Sl No",
      size: 30,
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 50,
    },
    {
      accessorKey: "transactionId",
      header: "Transaction ID",
      size: 100,
    },
    {
      accessorKey: "customerName",
      header: "Customer Name",
      size: 50,
    },
    {
      accessorKey: "mobile",
      header: "Mobile No",
      size: 50,
    },
    {
      accessorKey: "finalAmount",
      header: "Final Amount",
      size: 50,
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 100,
    },
  ];

  const data = salesData?.map((item, idx) => {
    return {
      slno: idx + 1,
      date: Utility?.formatDate(item?.createdAt),
      transactionId: "#" + item?.trID,
      customerName: item?.trsCustomerName,
      mobile: item?.trsCustomerPhoneNo,
      finalAmount: Utility.MoneyFormatter(item?.trsFinalAmount),
      action: (
        <GlobalButton
          name="View"
          background="#dc2329"
          color="#fff"
          onclick={() => handelView(item)}
        />
      ),
    };
  });

  return (
    <Container className="expenseDataContainerSales">
      <Row className="sales-head">
        <Col sm={6} xs={12} className="topexpense" style={{ color: "black" }}>
          Sales Data
        </Col>
        <Col sm={6} xs={12} className="salestopsecondexpense">
          <GlobalButton
            name="Add New"
            background="#dc2329"
            color="#fff"
            icon={<GoPlusCircle />}
            fontWeight="500"
            onclick={handleSaleForm}
          />
          {/* <GlobalButton
            name="Filter"
            background="#e4cdaa"
            color="#642315"
            icon={<IoFilter />}
            fontWeight="500"
          /> */}
        </Col>
      </Row>
      <Row>
        {!loading ? (
          <DataTable columns={columns} data={data} />
        ) : (
          <div className=" Loader">
            <CircularIndeterminate />
          </div>
        )}
      </Row>
    </Container>
  );
};

export default SalesData;
