import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GoPlusCircle } from "react-icons/go";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import "./BillsReport.css";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { useDispatch, useSelector } from "react-redux";
import { AccountsController } from "../../../Redux/controllers/AccountsController";
import { FaDownload } from "react-icons/fa";
import Utility from "../../../Utils/Utility";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";
import { logOut } from "../../../Redux/Slice/userSlice";

export default function Billandreport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((st) => st?.user);
  const [allOrders, setAllOrders] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const [loading, setLoading] = useState(false);

  const handleNavigate = () => {
    navigate("/BillGenerateInvoice");
  };

  const getAllOrders = async () => {
    try {
      setLoading(true);
      const { data } = await AccountsController.getInvoiceDetailsAPI(token);
      setAllOrders(data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Utility.eToast(error?.response?.data?.message || error?.message);
      if (
        error?.response?.data?.message ===
        "token has expired , please login again"
      ) {
        dispatch(logOut());
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  const column = [
    {
      accessorKey: "slNo",
      header: "Sl No",
      size: 30,
    },
    {
      accessorKey: "invoiceID",
      header: "Invoice ID",
      size: 100,
    },
    {
      accessorKey: "orderID",
      header: "Order ID",
      size: 150,
    },
    {
      accessorKey: "invoiceDate",
      header: "Invoice Date",
      size: 50,
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 150,
    },
  ];

  const data = allOrders?.map((item, i) => ({
    slNo: i + 1,
    invoiceID: (item?.invoiceID && "#" + item?.invoiceID) || "N/A",
    orderID: (item?.orderID?.orderID && "#" + item?.orderID?.orderID) || "N/A",
    invoiceDate: Utility.formatDate(item?.updatedAt),
    action: (
      <div className="d-flex gap-3">
        <GlobalButton
          name="Invoice"
          background="#dc2329"
          color="#fff"
          fontFamily="Roboto"
          paddingLeft="0.5rem"
          paddingRight="0.5rem"
          icon={<FaDownload />}
          onclick={() => window.open(item?.invoice)}
        />
      </div>
    ),
  }));

  return (
    <Container id="transaction-primary-container">
      <Row id="vendorbuttonrow1">
        <Col id="vendor-button">
          <div className="vendorbutton1">
            <GlobalButton
              name="Generate new Invoice"
              background="#dc2329"
              color="#fff"
              icon={<GoPlusCircle />}
              fontWeight="bold"
              onclick={handleNavigate}
            />
          </div>
        </Col>
        <Col id="bils" style={{ color: "black" }}>
          Bills & Invoices
        </Col>
      </Row>
      <Row className="villrow">
        {!loading ? (
          <DataTable columns={column} data={data} />
        ) : (
          <div className=" Loader">
            <CircularIndeterminate />
          </div>
        )}
      </Row>
    </Container>
  );
}
