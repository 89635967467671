import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Container } from "react-bootstrap";
import "./LogoutModal.css";

const LogoutModal = (props) => {
  const [value, setValue] = useState("");
  const handelSubmit = (e) => {
    const selectedValue = e.target.value;
    setValue(selectedValue);
    props.onConfirm(selectedValue);
    props.onHide();
  };
  console.log("Value", value);

  return (
    <Modal id="transactionModal" {...props} size="md">
      <Modal.Header closeButton style={{ background: "#f4f3f0" }}>
        <Modal.Title style={{ color: "#black", background: "" }}></Modal.Title>
      </Modal.Header>
      <Modal.Body className="Container">
        <Row>
          <Col>
            <h5 style={{ color: "black" }}> Are you sure to logout ?</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <button onClick={handelSubmit} className="BUTTON" value="cancel">
              Cancel
            </button>
          </Col>
          <Col>
            <button onClick={handelSubmit} className="BUTTON" value="ok">
              OK
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default LogoutModal;
