import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Container, Form } from "react-bootstrap";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import "./UpdateSalery.css";
import { AccountsController } from "../../../../Redux/controllers/AccountsController";
import Utility from "../../../../Utils/Utility";

const UpdateSalery = ({ onHide, show, employee }) => {
  const getBasicSalary = () => {
    try {
      let basicSalary = parseFloat(employee?.empSalaryPerFrequency);
      if (employee?.empPayFrequency === "hourly") {
        let workingHour = parseFloat(employee?.lastMonthWorkingHours);
        return basicSalary * workingHour;
      }
      return basicSalary;
    } catch (error) {}
  };
  const [AllInput, setInput] = useState({
    basic_salery: employee?.empSalaryPerFrequency ? getBasicSalary() : 0,
    overtime_pay: "",
    allowances: "",
    total_earnings: "",
    taxes: "",
    others: "",
    total_deduction: "",
    total_amount: "",
    notes: "",
  });
  const [basicSaleryValid, setBasicSaleryValid] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = async () => {
    let isValid = true;
    if (!AllInput.basic_salery) {
      setBasicSaleryValid("This field is required");
      isValid = false;
    } else {
      setBasicSaleryValid("");
      isValid = true;
    }
    if (isValid) {
      const DataToBeSubmited = {
        empId: employee?._id,
        basicSalary: AllInput.basic_salery,
        overTimePay: AllInput.overtime_pay,
        allowances: AllInput.allowances,
        taxRate: AllInput.taxes,
        notes: AllInput.notes,
      };
      try {
        const response = await AccountsController.UpdateSalery(
          DataToBeSubmited
        );
        onHide();
        console.log("here is the final response ", response);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const calculateTotals = () => {
    const basic_salery = parseFloat(AllInput.basic_salery) || 0;
    const overtime_pay = parseFloat(AllInput.overtime_pay) || 0;
    const allowances = parseFloat(AllInput.allowances) || 0;
    const total_earnings = basic_salery + overtime_pay + allowances;

    const taxes = parseFloat(AllInput.taxes) || 0;
    const others = parseFloat(AllInput.others) || 0;
    const total_deduction = (total_earnings * taxes) / 100 + others;

    const total_amount = total_earnings - total_deduction;

    setInput((prev) => ({
      ...prev,
      total_earnings: total_earnings.toFixed(2),
      total_deduction: total_deduction.toFixed(2),
      total_amount: total_amount.toFixed(2),
    }));
  };

  useEffect(() => {
    calculateTotals();
  }, [
    AllInput.basic_salery,
    AllInput.overtime_pay,
    AllInput.allowances,
    AllInput.taxes,
    AllInput.others,
  ]);

  return (
    <Container className="p-2">
      <Modal id="transactionModal" show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton id="ModalHeaderSalery">
          <Modal.Title style={{ color: "black", background: "#f4f3f0" }}>
            Salary Update
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="updateSaleryModal">
            {employee?.empPayFrequency === "hourly" && (
              <>
                <Row className="my-1">
                  <Col md={6} sm={6} xs={6} lg={6} xl={6}>
                    Pay Frequency
                  </Col>
                  <Col md={6} sm={6} xs={6} lg={6} xl={6} id="view-col">
                    {employee?.empPayFrequency
                      ? employee?.empPayFrequency?.charAt(0).toUpperCase() +
                        employee?.empPayFrequency.slice(1)
                      : "N/A"}
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col md={6} sm={6} xs={6} lg={6} xl={6}>
                    Last Month ({Utility.getLastMonthName()}) working
                  </Col>
                  <Col md={6} sm={6} xs={6} lg={6} xl={6} id="view-col">
                    {employee?.lastMonthWorkingHours
                      ? parseFloat(employee?.lastMonthWorkingHours)
                      : 0}{" "}
                    hr
                  </Col>
                </Row>
              </>
            )}
            <Form>
              <Row>
                <Form.Label
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Earnings
                </Form.Label>
              </Row>
              <Row id="ADNsubadminRow">
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Basic Salary</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter basic salary"
                    id="subadminFormcontrol"
                    name="basic_salery"
                    isInvalid={!!basicSaleryValid}
                    onChange={handleChange}
                    value={AllInput?.basic_salery}
                  />
                  <span style={{ color: "red" }}>{basicSaleryValid}</span>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Overtime Pay</Form.Label>
                  <br />
                  <Form.Control
                    type="number"
                    id="subadminFormcontrol"
                    className="custom-date-input"
                    name="overtime_pay"
                    placeholder="Enter overtime Pay"
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="allowances">Allowances</Form.Label>
                  <br />
                  <Form.Control
                    type="number"
                    id="subadminFormcontrol"
                    className="custom-date-input"
                    name="allowances"
                    placeholder="Enter Allowances"
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12} md={12}>
                  <Form.Label id="allowances">Total Earnings</Form.Label>
                  <br />
                  <Form.Control
                    type="number"
                    disabled
                    id="subadminFormcontrol"
                    className="custom-date-input"
                    name="total_earnings"
                    value={AllInput.total_earnings}
                    placeholder="Enter Total Earnings"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Form.Label
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "black",
                }}
              >
                Deductions
              </Form.Label>

              <Row id="ADNsubadminRow">
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Taxes(in %age)</Form.Label>
                  <Form.Control
                    placeholder="Enter Tax for Employee"
                    type="number"
                    id="subadminFormcontrol"
                    name="taxes"
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Others</Form.Label>
                  <br />
                  <Form.Control
                    type="number"
                    id="subadminFormcontrol"
                    className="custom-date-input"
                    name="others"
                    placeholder="Enter Other Deductions"
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12} md={12}>
                  <Form.Label id="allowances">Total Deductions</Form.Label>
                  <br />
                  <Form.Control
                    type="text"
                    disabled
                    value={AllInput.total_deduction}
                    id="subadminFormcontrol"
                    className="custom-date-input"
                    name="total_deduction"
                    placeholder="Enter Total Deductions"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Row id="ADNsubadminRow">
                <Form.Label
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "black",
                  }}
                >
                  Net Pay
                </Form.Label>
              </Row>

              <Row id="ADNsubadminRow">
                <Col xs={12} md={12}>
                  <Form.Label id="ADNsubadminForm">
                    Total Amount Salary
                  </Form.Label>
                  <Form.Control
                    disabled
                    value={AllInput.total_amount}
                    placeholder="Enter Total Salery "
                    id="subadminFormcontrol"
                    name="total_amount"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Row id="ADNsubadminRow">
                <Col xs={12} md={12}>
                  <Form.Label id="ADNsubadminForm">Notes</Form.Label>
                  <Form.Control
                    placeholder="Write Notes"
                    id="subadminFormcontrol"
                    name="notes"
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Row className="sallastend">
                <div style={{ width: "fit-content" }}>
                  <GlobalButton
                    name="Cancel"
                    background="#dc2329"
                    color="#fff"
                    // border="1px solid #595959"
                    borderradius="7px"
                    onclick={() => onHide()}
                  />
                </div>
                <div style={{ width: "fit-content" }}>
                  <GlobalButton
                    name="Update"
                    background="#dc2329"
                    color="#fff"
                    // border="1px solid #595959"
                    borderradius="7px"
                    onclick={handleSubmit}
                  />
                </div>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default UpdateSalery;
