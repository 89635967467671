import React from "react";
import "./ViewTransactionForm.css";
import {
  Row,
  Col,
  Form,
  Image,
  Button,
  Container,
  Modal,
} from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoDownload } from "react-icons/go";
export default function ViewTransactionForm(props) {
  const navigate = useNavigate();
  return (
    <>
      <Container id="modal">
        <Container id="transaction-container">
          <Row id="transaction-detailed-row">
            <Col md={11} xs={10} id="transaction-information">
              Transaction Information
            </Col>
            <Col md={1} xs={2}>
              {" "}
              <RxCross1 id="transaction-crossicon" onClick={props.onHide} />
            </Col>
          </Row>
          <Row id="text-row">
            <Col id="Transaction-details">Transaction Details</Col>
          </Row>
          <hr id="hrtag" />
          <Row id="text-row1">
            <Col md={6} sm={6} xs={6} className="text-class">
              Transaction ID
            </Col>
            <Col md={6} sm={6} xs={6} className="text-class" id="value-column">
              TRX2024001
            </Col>
          </Row>
          <Row id="text-row1">
            <Col md={6} sm={6} xs={6} className="text-class">
              Transaction Date
            </Col>
            <Col md={6} sm={6} xs={6} className="text-class" id="value-column">
              19 April 2024
            </Col>
          </Row>
          <Row id="text-row1">
            <Col md={6} sm={6} xs={6} className="text-class">
              Transaction Type
            </Col>
            <Col md={6} sm={6} xs={6} className="text-class" id="value-column">
              Purchase
            </Col>
          </Row>
          <Row id="text-row2">
            <Col id="Transaction-details">Transaction Details</Col>
          </Row>
          <hr id="hrtag" />
          <Row id="text-row1">
            <Col md={6} sm={6} xs={6} className="text-class">
              Transaction Description
            </Col>
            <Col md={6} sm={6} xs={6} className="text-class" id="value-column">
              Buy Light Materials
            </Col>
          </Row>
          <Row id="text-row1">
            <Col md={6} sm={6} xs={6} className="text-class">
              Transaction Party
            </Col>
            <Col md={6} sm={6} xs={6} className="text-class" id="value-column">
              XYZ Electronics Supplier
            </Col>
          </Row>
          <Row id="text-row1">
            <Col md={6} sm={6} xs={6} className="text-class">
              Transaction Party Mobile Number
            </Col>
            <Col md={6} sm={6} xs={6} className="text-class" id="value-column">
              +91 639874561
            </Col>
          </Row>
          <Row id="text-row1">
            <Col md={6} sm={6} xs={6} className="text-class">
              Payment Method
            </Col>
            <Col md={6} sm={6} xs={6} className="text-class" id="value-column">
              UPI Transfer
            </Col>
          </Row>
          <Row id="text-row1">
            <Col md={6} sm={6} xs={6} className="text-class">
              Transaction Amount
            </Col>
            <Col md={6} sm={6} xs={6} className="text-class" id="value-column">
              $12500
            </Col>
          </Row>
          <Row id="text-row1">
            <Col md={6} sm={6} xs={6} className="text-class">
              Transaction ID
            </Col>
            <Col md={6} sm={6} xs={6} className="text-class" id="value-column">
              Not Available
            </Col>
          </Row>
          <Row id="text-row2">
            <Col id="Transaction-details">View Bill</Col>
          </Row>
          <hr id="hrtag" />
          <Row>
            <Col>
              <Button id="download-transaction">
                Download Transaction Bill <GoDownload id="download-icon" />
              </Button>
            </Col>
          </Row>
          <Row id="button-row">
            <Col md={11} xs={10} sm={10} lg={11} id="delete-button">
              <Button type="reset" className="button">
                Delete
              </Button>
            </Col>
            <Col md={1} xs={2} sm={2} lg={1} id="edit-button">
              <Button className="button">Edit</Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
