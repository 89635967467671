import React, { useState } from "react";
import {
  Row,
  Col,
  Modal,
  Container,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import "./RecordPaymentModal.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Utility from "../../../Utils/Utility";
import CircularIndeterminate from "./../../../globalcomponents/Spinner/Spinner";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { UserController } from "../../../Redux/controllers/UserController";
import { AccountsController } from "../../../Redux/controllers/AccountsController";

const RecordPaymentModal = (props) => {
  const navigate = useNavigate();
  const [paymentDetails, setPaymentDetails] = useState({
    orderID: props?.orderID || "",
    paymentDate: "",
    receivedPayment: 0,
    paymentMethod: "",
    paymentDoc: "",
  });
  const [loading, setLoading] = useState(false);

  const { token } = useSelector((st) => st?.user);
  const handleDocUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("doc", file);
      const { data } = await UserController.SingleImageUploader(formData);
      Utility?.sToast(data?.message);
      setPaymentDetails({ ...paymentDetails, paymentDoc: data?.doc });
    } catch (error) {
      Utility?.eToast(error?.response?.data?.message || error?.message);
    }
  };
  const handleRecordPayment = async () => {
    if (!paymentDetails.orderID) {
      return Utility.eToast("Provide Order ID");
    }
    if (!paymentDetails.receivedPayment) {
      return Utility.eToast("Provide payment amount");
    }
    if (!paymentDetails.paymentMethod) {
      return Utility.eToast("Select payment method");
    }
    try {
      setLoading(true);
      const { data } = await AccountsController.recordPaymentDetailsAPI(
        paymentDetails,
        token
      );
      setLoading(false);
      Utility.sToast(data?.message);
      props.onHide();
    } catch (error) {
      setLoading(false);
      Utility?.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    console.log(e);
    setPaymentDetails({ ...paymentDetails, [e.target.name]: e.target.value });
  };

  return (
    <Container className="p-2">
      <Modal id="transactionModal" {...props} size="lg">
        <Modal.Header closeButton style={{ background: "#f4f3f0" }}>
          <Modal.Title style={{ color: "black", background: "#f4f3f0" }}>
            Record New Payment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row id="transaction4">
              <Col xs={12} md={6}>
                <Form.Label id="transaction5">Order ID</Form.Label>
                <Form.Control
                  placeholder=""
                  id="formid123"
                  disabled
                  value={paymentDetails?.orderID}
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Label id="transaction5">Date</Form.Label>
                <br />
                <Form.Control
                  type="date"
                  id="formid123"
                  class="custom-date-input"
                  // value={paymentDetails?.paymentDate}
                  name={"paymentDate"}
                />
              </Col>
            </Row>
            <Row id="transaction4">
              <Col xs={12} md={6}>
                <Form.Label id="transaction5">Amount Received</Form.Label>
                <Form.Control
                  placeholder=""
                  type="number"
                  id="formid123"
                  onChange={handleChange}
                  value={paymentDetails?.receivedPayment}
                  name={"receivedPayment"}
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Label id="ADNsubadminForm">Payment Method</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={handleChange}
                  name="paymentMethod"
                  // isInvalid={errors.paymentMethod}
                >
                  <option>Choose Payment Method</option>
                  <option value="bank_transfer_or_upi">
                    Upi/Bank Transfer
                  </option>
                  <option value="cash">Cash</option>
                  <option value="check">Check</option>
                </Form.Select>
                {/* <Form.Control.Feedback type="invalid">
                  {errors.paymentMethod}
                </Form.Control.Feedback> */}
              </Col>
            </Row>
            <Row id="transaction4">
              <Col xs={12}>
                <Form.Label id="transaction5">Any Relevant Doc</Form.Label>
                <Form.Control
                  placeholder="123698547000"
                  type="file"
                  id="formid123"
                  onChange={handleDocUpload}
                />
              </Col>
            </Row>
            <Row id="transaction4">
              <Col xs={12} className="d-flex justify-content-end mt-lg-3">
                {loading ? (
                  <div style={{ marginRight: "30px" }}>
                    <CircularIndeterminate />
                  </div>
                ) : (
                  <GlobalButton
                    name={"Record Payment"}
                    fontFamily="Roboto"
                    background={"#dc2329"}
                    color={"#fff"}
                    border={"1px solid #642315"}
                    onclick={handleRecordPayment}
                  />
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default RecordPaymentModal;
//
