import React, { useEffect, useState } from "react";
import "./productform.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import Multiselect from "multiselect-react-dropdown";
import Utility from "../../../Utils/Utility";
import { CiEdit } from "react-icons/ci";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";

import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AddMaterialToInventory,
  updateProductItem,
} from "../../../Redux/Slice/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { UserController } from "../../../Redux/controllers/UserController";
import { FiPlusCircle } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

const units = ["m", "cm", "kg", "ft", "inch", "ltr", "ml", "unit", "other"];
const Productform = () => {
  const [images, setImages] = useState("");

  const [color, setColor] = useState("");
  const [selectedColor, setSelectedColor] = useState("#642315");
  const [showAddColor, setShowAddColor] = useState(false);
  const [size, setSize] = useState([]);
  const [pattern, setPattern] = useState("");
  const [initialValues, setInitialValues] = useState(null);
  const { userInfo } = useSelector((state) => state?.user);
  const [allData, setAllData] = useState([]);
  const [inputs, setInputs] = useState({
    name: "",
    stock: "",
    unit: "",
    size: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingPicture, setLoadingPicture] = useState(false);
  const [quillValue, setQuillValue] = useState(" ");

  const token = useSelector((state) => state?.user?.token);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isEditing, productToEdit } = location.state || {};

  useEffect(() => {
    if (isEditing && productToEdit) {
      setInitialValues(productToEdit);
      setInputs(productToEdit);
    }
  }, [isEditing, productToEdit]);

  const handlePatternChange = async (e) => {
    setLoadingPicture(true);
    const file = e.target.files[0];
    console.log(file);

    if (file) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("doc", file);
        const response = await UserController.SingleImageUploader(formData);

        const imageUrl = response.data.doc;

        setImages(imageUrl);
        setLoading(false);
      } catch (error) {
        console.error("Error uploading image:", error);
        setLoading(false);
      }
    }
  };

  const data = [
    { cat: "Group 1", key: "Small (4 Seater):L48 X W30" },
    { cat: "Group 1", key: "Medium (6 Seater):L60 X W36" },
    { cat: "Group 1", key: "Large (8 Seater):L72 X W42" },
    { cat: "Group 1", key: "XL (10 Seater):L84 X W48" },
  ];

  const[otherUnitName , setOtherUnitName] =useState("")
  const FinalData = { ...inputs, color, pattern: images };

  const handlechange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: name === "stock" ? Number(value) : value,
    }));
  };
  const handleAddProduct = async () => {
    const { name, stock, unit } = inputs;

    if (!name) {
      Utility.eToast("Name is required");
      return;
    }

    if (!stock) {
      Utility.eToast("Stock is required");
      return;
    }

    if (!unit) {
      Utility.eToast("Unit is required");
      return;
    }

    if (unit === "other" && otherUnitName.length == 0 ) {
      Utility.eToast("Unit name is required");
      return;
    }

    if (isEditing && productToEdit) {
      console.log(data);
      try {
        await dispatch(updateProductItem(data)).then(unwrapResult);
        navigate(-1);
        Utility.sToast("Product updated successfully!");
      } catch (err) {
        console.log("err", err);
        Utility.eToast("Something went wrong");
      }
    } else {
      if (FinalData.unit === "other") {
        FinalData.unit = otherUnitName
      }
      console.log(
        "Data to be sent to API:",
        FinalData
      );
      try {
        await dispatch(AddMaterialToInventory({ data: FinalData, token })).then(
          unwrapResult
        );
        Utility.sToast("Product added successfully!");
        navigate(-1);
      } catch (err) {
        console.log("err", err);
        Utility.eToast("Something went wrong");
      }
    }
  };

  const handleReset = () => {
    setInputs("");
    setColor("");
    setPattern("");
    setImages("");
    setOtherUnitName("")
  };
  return (
    <Container className="subadminform">
      <Row id="amtopbarsalery">
        <Col md={11} xs={10} id="firstnames">
          {isEditing ? "Edit Material" : "Add New Material"}
        </Col>

        <Col md={1} xs={2} className="admincrossicon">
          <RxCross2 id="transaction-crossicon" onClick={() => navigate(-1)} />
        </Col>
      </Row>

      <Form>
        <Row>
          <Col sm={6} className="titleofcontainerrrr">
            <p7 className="titleofcontainer">Material Name</p7>
            <br />
            <Form.Control
              type="text"
              placeholder="Name of Material"
              className="amthirdcol"
              name="name"
              value={inputs.name || ""}
              onChange={handlechange}
            />
          </Col>

          <Col sm={6} className="titleofcontainerrrr">
            <p7 className="titleofcontainer">Size (if any)</p7>
            <br />
            <Form.Control
              type="text"
              placeholder="Enter Size"
              className="amthirdcol"
              name="size"
              value={inputs.size || ""}
              onChange={handlechange}
            />
          </Col>
        </Row>

        <Row className="prodoductfoamrow">
          <Col sm={6} className="titleofcontainerrrr">
            <p className="titleofcontainer">Color (if any)</p>
            <div className="colorandbuttoncontainer">
              <div className="color-container">
                {color && (
                  <div className="color-box-container">
                    <div
                      className="color-box"
                      style={{ backgroundColor: color }}
                    ></div>
                    <RxCross2
                      className="cross-mark"
                      onClick={() => setColor("")}
                    />
                  </div>
                )}
              </div>
              {showAddColor ? (
                <Col className="color-container">
                  <Form.Control
                    type="color"
                    defaultValue="#642315"
                    title="Choose your color"
                    onChange={(e) => setSelectedColor(e.target.value)}
                  />
                  <Button
                    className="add-btn"
                    size="sm"
                    onClick={() => {
                      setColor(selectedColor);
                      setShowAddColor(false);
                    }}
                  >
                    Add
                  </Button>
                </Col>
              ) : color ? (
                <CiEdit
                  onClick={() => setShowAddColor(true)}
                  className="add-button"
                />
              ) : (
                <FiPlusCircle
                  onClick={() => setShowAddColor(true)}
                  className="add-button"
                />
              )}
            </div>
          </Col>

          <Col sm={6} className="titleofcontainerrrr">
            <p className="titleofcontainer">
              Choose Pattern / Textures (if any)
            </p>
            <div className="patternofproductfprm">
              <input
                type="file"
                placeholder="Add Textures"
                className=""
                onChange={handlePatternChange}
              />
              <div className="pattern">
                {loading ? (
                  <div
                    style={{ position: "relative" }}
                    className="imageInPattern"
                  >
                    <CircularIndeterminate size={20} />
                  </div>
                ) : (
                  images && ( // Check if 'images' has a value
                    <div style={{ position: "relative" }}>
                      <img
                        src={images}
                        className="imageInPattern"
                        alt="pattern"
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm={6} className="titleofcontainerrrr">
            <p7 className="titleofcontainer">Stock</p7>
            <br />
            <Form.Control
              type="text"
              placeholder="Number of stock"
              className="amthirdcol"
              name="stock"
              value={inputs.stock || ""}
              onChange={handlechange}
            />
          </Col>

          <Col sm={6} className="titleofcontainerrrr">
            <p7 className="titleofcontainer"> Unit</p7>
            <br />
            <select
              id="cars"
              data-placeholder=""
              className="selecttagsubadminform"
              name="unit"
              value={inputs.unit || ""}
              onChange={handlechange}
            >
              <option value={""}>select unit</option>
              {units?.map((unit) => (
                <option value={unit}>{unit}</option>
              ))}
            </select>
          </Col>
        </Row>
        {inputs.unit === "other" && <Row>
          <Col sm={6} className="titleofcontainerrrr">
            <p7 className="titleofcontainer">Unit Name</p7>
            <br />
            <Form.Control
              type="unit"
              value={otherUnitName}
              placeholder="Enter unit name"
              className="amthirdcol"
              name="unit"
              onChange={(e)=>setOtherUnitName(e.target.value)}
            />
          </Col>
        </Row>}

        <Row className="subadminlastbutton">
          <GlobalButton
            name={"Reset"}
            paddingTop={"0.4rem"}
            paddingBottom={"0.4rem"}
            paddingLeft={"1rem"}
            paddingRight={"1rem"}
            fontFamily="Roboto"
            border={"1px solid #595959"}
            background={"#E4CDAA"}
            color={"#642315"}
            width={"3rem"}
            onclick={handleReset}
          />
          <GlobalButton
            name={"Submit"}
            paddingTop={"0.4rem"}
            paddingBottom={"0.4rem"}
            paddingLeft={"1rem"}
            paddingRight={"1rem"}
            fontFamily="Roboto"
            border={"1px solid #595959"}
            background={"#E4CDAA"}
            color={"#642315"}
            width={"3rem"}
            onclick={handleAddProduct}
          />
        </Row>
      </Form>
    </Container>
  );
};

export default Productform;
