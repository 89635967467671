import { useSelector } from "react-redux";
import { HttpClient } from "./HttpClient";

export const addToInventory = (data) => {
  let url = "material/add-material";
  console.log("http token    ==========", data.token);
  return HttpClient.apiCaller(url, "POST", data.data, data.token);
};

export const login = (data) => {
  let url = "auth/subadmin-login";
  return HttpClient.apiCaller(url, "POST", data);
};
export const SingleImageUploader = (data) => {
  let url = "upload-single-doc";
  return HttpClient.apiCaller(url, "POST", data, "", "multipart/form-data");
};
export const getAllNotification = (token, id) => {
  let url = `notify/get-notification/${id}`;
  return HttpClient.apiCaller(url, "GET", null, token);
};
export const deleteAllNotificationById = (token, id) => {
  let url = `notify/delete-all-notification/${id}`;
  return HttpClient.apiCaller(url, "DELETE", {}, token);
};
export const deleteNotificationById = (token, id) => {
  let url = `notify/delete-notification/${id}`;
  return HttpClient.apiCaller(url, "DELETE", {}, token);
};

export const getMaterialDetailsAPI = (token) => {
  let url = "material/get-all-materials";
  return HttpClient.apiCaller(url, "GET", null, token);
};
export const getAllRequestDetailsAPI = (token) => {
  let url = "request/get-all-requests";
  return HttpClient.apiCaller(url, "GET", null, token);
};

export const approveRequestById = (data) => {
  console.log(data);
  let url = `request/approve-request/${data?.id}`;
  return HttpClient.apiCaller(url, "PUT", {}, data?.token);
};
export const updateMaterialStock = (data) => {
  console.log(data);
  let url = `material/update-material/${data?.id}`;
  return HttpClient.apiCaller(url, "PUT", data?.updatedData, data?.token);
};

export const getDashboardStatistics = (token) => {
  let url = `stats/dashboard-details`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
export const employeeChartDetails = (token)=>{
  let url =`stats/chart/employee`;
  return HttpClient.apiCaller(url, "GET", {}, token);
}
export const customerChartDetails = (token)=>{
  let url =`stats/chart/customer`;
  return HttpClient.apiCaller(url, "GET", {}, token);
}
export const orderChartDetails = (token)=>{
  let url =`stats/chart/order`;
  return HttpClient.apiCaller(url, "GET", {}, token);
}

export const financialChartDetails = (token)=>{
  let url = `stats/chart/financial`;
  return HttpClient.apiCaller(url, "GET", {}, token);
}

export const inventoryStatistics = (token)=>{
  let url = `stats/dashboard-details/inventory`;
  return HttpClient.apiCaller(url, "GET",{}, token);
}

export const UserController = {
  getAllRequestDetailsAPI,
  addToInventory,
  login,
  SingleImageUploader,
  getMaterialDetailsAPI,
  deleteAllNotificationById,
  approveRequestById,
  updateMaterialStock,
  getAllNotification,
  deleteNotificationById,
  getDashboardStatistics,
  employeeChartDetails,
  customerChartDetails,
  orderChartDetails,
  financialChartDetails,
  inventoryStatistics
};
