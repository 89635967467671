import React, { useState } from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import "./ViewSalesData.css";
import { RxCross1 } from "react-icons/rx";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import Utility from "../../../Utils/Utility";
import { AccountsController } from "./../../../Redux/controllers/AccountsController";
import { useSelector } from "react-redux";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";

export default function ViewSalesData(props) {
  const [spinner, setSpinner] = useState(false);
  const { token } = useSelector((st) => st?.user);
  const location = useLocation();
  const item = location?.state;
  console.log(item);
  const navigate = useNavigate();
  if (!item) {
    navigate(-1);
  }
  const handleGenerateTransactionInvoice = async () => {
    setSpinner(true);
    try {
      const { data } = await AccountsController.generateTransactionInvoiceAPI(
        token,
        item?._id
      );
      window.open(data?.data, "_blank");
      setSpinner(false);
    } catch (error) {
      Utility?.eToast(error?.response?.dat?.message || error?.message);
      setSpinner(false);
    }
  };
  return (
    <>
      <Container id="SalesContainer">
        <Row className="saleviewtop">
          <Col sm={6} className="leftContent">
            Sales Transaction Information
          </Col>
          <Col sm={6} className="rightContent">
            <RxCross1 onClick={() => navigate(-1)} />
          </Col>
        </Row>
        <Row id="container-row">
          <Col>
            <Row id="heading-row">
              <Col id="heading-col">Transaction Details</Col>
            </Row>
            <hr />
            <Row className="rowData">
              <Col className="key">ID</Col>
              <Col id="view-col">{item?.trID ? "#" + item?.trID : "N/A"}</Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Date</Col>
              <Col id="view-col">
                {Utility?.formatDate(item?.createdAt) || "N/A"}
              </Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Description</Col>
              <Col id="view-col">{item?.trDescription || "N/A"}</Col>
            </Row>

            <Row id="heading-row">
              <Col id="heading-col">Product Details</Col>
            </Row>
            <hr id="hr-tag" />
            {item?.trsProductDetails?.map((data) => {
              return (
                <>
                  {" "}
                  <Row className="rowData">
                    <Col className="key">ID</Col>
                    <Col id="view-col">{data?.trsProductID || "N/A"}</Col>
                  </Row>
                  <Row className="rowData">
                    <Col className="key">Name</Col>
                    <Col id="view-col">{data?.trsProductName || "N/A"}</Col>
                  </Row>
                  <Row className="rowData">
                    <Col className="key">Base Amount</Col>
                    <Col id="view-col-num">
                      {Utility.MoneyFormatter(data?.trsBaseAmount)}
                    </Col>
                  </Row>
                  <Row className="rowData">
                    <Col className="key">Quantity</Col>
                    <Col id="view-col">
                      {data?.trsQuantity > 1
                        ? data?.trsQuantity + " units"
                        : "1 unit" || "1 unit"}
                    </Col>
                  </Row>
                  <Row className="rowData">
                    <Col className="key">GST </Col>
                    <Col id="view-col-num">
                      {data?.trsGST || "0"}
                      {" %"}
                    </Col>
                  </Row>
                  <Row className="rowData">
                    <Col className="key">Gross Amount</Col>
                    <Col id="view-col-num">
                      {Utility.MoneyFormatter(data?.trsGrossAmount)}
                    </Col>
                  </Row>
                  <hr id="hr-tag-small" />
                </>
              );
            })}
            {/* <hr id="hr-tag" /> */}
            <Row className="rowData">
              <Col className="key">Total Amount</Col>
              <Col id="view-col-num">
                {Utility.MoneyFormatter(item?.trsTotalAmount)}
              </Col>
            </Row>
            <Row id="heading-row">
              <Col id="heading-col">Amount Breakdown</Col>
            </Row>
            <hr />

            <Row className="rowData">
              <Col className="key">Discount</Col>
              <Col id={item?.discount ? "view-col-num" : "view-col"}>
                {item?.discount || "N/A"}
                {item?.discount && " %"}
              </Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Shipping Cost</Col>
              <Col id={item?.discount ? "view-col-num" : "view-col"}>
                {Utility.MoneyFormatter(item?.trsShippingFee)}
              </Col>
            </Row>

            <Row className="rowData">
              <Col className="key">Final Amount</Col>
              <Col id="view-col-num">
                {Utility.MoneyFormatter(item?.trsFinalAmount)}
              </Col>
            </Row>

            <Row id="heading-row">
              <Col id="heading-col">Payment Details</Col>
            </Row>
            <hr />
            <Row className="rowData">
              <Col className="key">Payment method</Col>
              <Col id="view-col">
                {Utility.getPaymentMethod(item?.trPaymentMethod) || "N/A"}
              </Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Payment ID(if online payment)</Col>
              <Col id="view-col">{item?.trPaymentID || "N/A"}</Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Payment Date</Col>
              <Col id="view-col">
                {(item?.trsPaymentDate &&
                  Utility?.formatDate(item?.trsPaymentDate)) ||
                  "N/A"}
              </Col>
            </Row>
            <Row id="heading-row">
              <Col id="heading-col">Shipping Details</Col>
            </Row>
            <hr />
            <Row className="rowData">
              <Col className="key">Customer Name</Col>
              <Col id="view-col">{item?.trsCustomerName || "N/A"}</Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Phone Number</Col>
              <Col id="view-col">{item?.trsCustomerPhoneNo || "N/A"}</Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Billing Address</Col>
              <Col id="view-col">{item?.trsBillingAddress || "N/A"}</Col>
            </Row>
            <Row className="rowData">
              <Col className="key">Shipping Address</Col>
              <Col id="view-col">{item?.trsShippingAddress || "N/A"}</Col>
            </Row>

            <Row className="rowData">
              <Col className="key">Notes</Col>
              <Col id="view-col">{item?.trAdditionalNotes || "N/A"}</Col>
            </Row>
          </Col>
        </Row>
        <Row className="invoiceForm">
          <Col className="d-flex w-100 justify-content-end my-lg-4">
            {spinner ? (
              <CircularIndeterminate />
            ) : (
              <GlobalButton
                name={"Generate Invoice"}
                background="#dc2329"
                color="#fff"
                icon={<BsFileEarmarkPdfFill />}
                onclick={handleGenerateTransactionInvoice}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}
