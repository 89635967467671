import React, { useState } from "react";
import { Row, Col, Modal, Container, Form, Button } from "react-bootstrap";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import "./OrderDetailsModal.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserController } from "../../../../Redux/controllers/UserController";
import Utility from "../../../../Utils/Utility";
import CircularIndeterminate from "../../../../globalcomponents/Spinner/Spinner";

const OrderDetailsModal = (props) => {
  const navigate = useNavigate();
  const [type, setType] = useState("stock_in");
  const [stock, setStock] = useState(null);
  const token = useSelector((st) => st?.user?.token);
  const [loading, setLoading] = useState(false);

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleUpdateStock = async (e) => {
    e.preventDefault();
    try {
      if (!stock) {
        return Utility.eToast("please enter stock quantity");
      }
      setLoading(true);
      const { data } = await UserController?.updateMaterialStock({
        updatedData: {
          type,
          stock: +stock,
        },
        token,
        id: props?.material?._id,
      });
      Utility.sToast(data?.message);
      props.onHide();
    } catch (error) {
      Utility?.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="p-2">
      <Modal id="transactionModal" {...props} size="lg">
        <Modal.Header closeButton style={{ background: "#E4CDAA" }}>
          <Modal.Title style={{ color: "#642315E5", background: "#E4CDAA" }}>
            Update Material Stock
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group className="mb-3 mt-3"> */}
            {/* <Form.Control
                className="custom-placeholder"
                placeholder="Enter your Name"
                name="name"
                // value={}
                // onChange={handleChange}
              ></Form.Control>
            </Form.Group> */}
            <div className="radio-container">
              <Form.Check
                inline
                label="Stock In"
                name="stock"
                type="radio"
                id="stock-in"
                value="stock_in"
                checked={type === "stock_in"}
                onChange={handleTypeChange}
              />
              <Form.Check
                inline
                label="Stock Out"
                name="stock"
                type="radio"
                id="stock-out"
                value="stock_out"
                checked={type === "stock_out"}
                onChange={handleTypeChange}
              />
            </div>
            <Col xs={6} className="m-lg-auto my-lg-2">
              <Form.Group
                className="mb-3 d-flex gap-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  className="custom-placeholder"
                  type="number"
                  placeholder="Enter quantity"
                  value={stock}
                  onChange={(e) => setStock(e.target.value)}
                />
                <GlobalButton
                  name={props?.material?.unit}
                  background="#E4CDAA"
                  disabledBgColor="#E4CDAA"
                  color="#642315"
                  disabled={true}
                />
              </Form.Group>
            </Col>
          </Form>
          <Row>
            <Col className="subadmin-btn">
              {loading ? (
                <CircularIndeterminate />
              ) : (
                <GlobalButton
                  name={"Update Stock"}
                  fontFamily="Roboto"
                  background={"#E4CDAA"}
                  color={"#642315"}
                  border={"1px solid #642315"}
                  onclick={handleUpdateStock}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default OrderDetailsModal;
