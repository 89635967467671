import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Container } from "react-bootstrap";
import "./ViewOrderHistory.css";
import { useNavigate, useLocation } from "react-router-dom";
import Utility from "../../../Utils/Utility";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { AccountsController } from "./../../../Redux/controllers/AccountsController";
import { useSelector } from "react-redux";
import DataTable from "./../../../globalcomponents/DataTable/DataTable";
import RecordPaymentModal from "../RecordPaymentModal/RecordPaymentModal";
import { RxCross1 } from "react-icons/rx";

const ViewOrderHistory = (props) => {
  const [isKeyFeaturesOpen, setIsKeyFeaturesOpen] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [allTasks, setAllTasks] = useState([]);
  const [allPayments, setAllPayments] = useState([]);
  const [showRecordPaymentModal, setShowRecordPaymentModal] = useState(false);
  const location = useLocation();
  const { selectedOrder } = location.state || {};
  // const selectedOrder = props.selectedOrder;
  const selectedProduct = selectedOrder?.productDetails;
  const selectedCustomer = selectedOrder?.customerDetails;
  const paymentDetails = selectedOrder?.paymentDetails;
  const { token } = useSelector((st) => st?.user);
  const navigate = useNavigate();

  console.log("product", selectedOrder);

  const getAllTasks = async () => {
    try {
      const { data } = await AccountsController.getAllTasksAPI(
        selectedOrder?._id,
        token
      );
      setAllTasks(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllPayments = async () => {
    try {
      const { data } = await AccountsController.getPaymentHistoryAPI(
        selectedOrder?._id,
        token
      );
      setAllPayments(data?.data?.paymentHistory);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllTasks();
    getAllPayments();
  }, [showRecordPaymentModal]);

  const taskColumn = [
    {
      accessorKey: "taskName",
      header: "Task",
      size: 150,
    },
    {
      accessorKey: "assignedEmployee",
      header: "Assigned Employee",
      size: 50,
    },
    {
      accessorKey: "empDepartment",
      header: "Department",
      size: 50,
    },

    // {
    //   accessorKey: "createdAt",
    //   header: "Assigned On",
    //   size: 50,
    // },
    {
      accessorKey: "deadline",
      header: "Deadline",
      size: 50,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 50,
    },
  ];
  const taskData = allTasks?.map((item, i) => ({
    taskName: item?.taskName || "N/A",
    assignedEmployee: item?.assignedEmployee?.name || "N/A",
    empDepartment: Utility.getDepartment(item?.empDepartment) || "N/A",
    // createdAt: Utility.formatDate(item?.createdAt) || "N/A",
    deadline: Utility.formatDate(item?.deadline) || "N/A",
    status: (
      <GlobalButton
        disabledBgColor={Utility.getStatusColor(item?.status)}
        disabled={true}
        name={
          item?.status?.charAt(0).toUpperCase() + item?.status?.slice(1) ||
          "N/A"
        }
        width="6rem"
        padding="2px"
        color="#fff"
      />
    ),
  }));
  const paymentColumn = [
    {
      accessorKey: "paymentDate",
      header: "Date",
      size: 50,
    },
    {
      accessorKey: "paymentMethod",
      header: "Payment Method",
      size: 50,
    },
    {
      accessorKey: "paymentAmount",
      header: "Amount Received",
      size: 50,
    },
  ];
  const paymentData = allPayments?.map((item, i) => ({
    paymentDate: Utility.formatDate(item?.paymentDate) || "N/A",
    paymentMethod: Utility.getPaymentMethod(item?.paymentMethod) || "N/A",
    paymentAmount: Utility.MoneyFormatter(item?.paymentAmount),
  }));
  const [ismaterialInfo, setismaterialInfo] = useState(false);

  const toggleKeyFeatures = () => {
    setIsKeyFeaturesOpen(!isKeyFeaturesOpen);
  };

  const toggleDescription = () => {
    setIsDescriptionOpen(!isDescriptionOpen);
  };
  const toggleMaterialInfo = () => {
    setismaterialInfo(!ismaterialInfo);
  };
  if (!selectedOrder) {
    return <div>No order selected</div>;
  }
  return (
    <>
      {showRecordPaymentModal && (
        <RecordPaymentModal
          onHide={() => setShowRecordPaymentModal(false)}
          show={RecordPaymentModal}
          orderID={selectedOrder?.orderID}
        />
      )}
      {/* <Container className="p-2"> */}
      <Container
        id="SalesContainer"
        // className="order-hist-modal"
        {...props}
        size="lg"
      >
        <Row
          className="sale-view-top"
          style={{ background: "#f4f3f0", color: "black" }}
        >
          <Col sm={6} className="">
            Order Information
          </Col>
          <Col sm={6} className="righttttconatint">
            <RxCross1 onClick={() => navigate(-1)} />
          </Col>
        </Row>
        <Row className="info-container">
          <Row className="mt-1">
            <div id="orderHistory">
              <h6 style={{ color: "black" }}>Product Information</h6>
            </div>

            {selectedProduct?.map((product, index) => (
              <div>
                <Row className="">
                  <span
                    style={{
                      marginTop: "8px",
                      marginBottom: "8px",
                      fontSize: "17px",
                      textDecoration: "underline",
                    }}
                  >
                    <strong>{`Product ${index + 1}`}</strong>
                  </span>
                  <Row className="mt-1">
                    <Col>Product Name</Col>
                    <Col className="value">
                      {product?.product?.name || "N/A"}
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col>Product ID</Col>
                    <Col className="value">
                      #{product?.product?.productID || "N/A"}
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col>Product Date Of Listing</Col>
                    <Col className="value">
                      {Utility.formatDate(product?.product?.createdAt) || "N/A"}
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col>Dimensions</Col>
                    <Col className="value">
                      {product?.requirements?.selectedSize || "N/A"}
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col>Selected Color</Col>
                    <Col className="value">
                      <div className="colorOptionBox">
                        {(
                          <div
                            className="colorOption"
                            style={{
                              backgroundColor: `${product?.requirements?.selectedColor}`,
                            }}
                          ></div>
                        ) || "N/A"}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col>Texture</Col>
                    <Col className="value">
                      <div className="textureBox">
                        {(
                          <img
                            className="texture"
                            src={product?.requirements?.selectedPattern}
                            alt="texture"
                          />
                        ) || "N/A"}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3 mb-1">
                    <Col>Key Features</Col>
                    <Col className="value">
                      {(
                        <>
                          <GlobalButton
                            name={isKeyFeaturesOpen ? "Close" : "View"}
                            background={"#dc2329"}
                            color={"#fff"}
                            padding={"0"}
                            onclick={toggleKeyFeatures}
                          />
                        </>
                      ) || "N/A"}
                    </Col>
                    <Col sm={12} xs={12}>
                      <div
                        className={`key-features-slider ${
                          isKeyFeaturesOpen ? "open" : ""
                        }`}
                      >
                        <div />
                        <h6>
                          {Utility.HTMLParser(
                            (product?.product?.keyFeatures || " ") ?? " "
                          )}
                        </h6>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-1 mb-1">
                    <Col>Additional Info</Col>
                    <Col className="value">
                      {(
                        <>
                          <GlobalButton
                            name={isDescriptionOpen ? "Close" : "View"}
                            background={"#dc2329"}
                            color={"#fff"}
                            padding={"0"}
                            onclick={toggleDescription}
                          />
                        </>
                      ) || "N/A"}
                    </Col>
                    <Col sm={12} xs={12}>
                      <div
                        className={`description-slider ${
                          isDescriptionOpen ? "open" : ""
                        }`}
                      >
                        <div />
                        <h6 className="descriptionparser">
                          {Utility.HTMLParser(
                            product?.product?.additionalInfo ?? " "
                          )}
                        </h6>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-1 mb-1">
                    <Col>Materials</Col>
                    <Col className="value">
                      {(
                        <>
                          <GlobalButton
                            name={ismaterialInfo ? "Close" : "View"}
                            background={"#dc2329"}
                            color={"#fff"}
                            padding={"0"}
                            onclick={toggleMaterialInfo}
                          />
                        </>
                      ) || "N/A"}
                    </Col>
                    <Col sm={12} xs={12}>
                      <div
                        className={`description-slider ${
                          ismaterialInfo ? "open" : ""
                        }`}
                      >
                        <div />
                        <h6 className="descriptionparser">
                          {Utility.HTMLParser(
                            product?.product?.materialInfo ?? " "
                          )}
                        </h6>
                      </div>
                    </Col>
                  </Row>
                </Row>

                <h6
                  style={{
                    marginTop: "8px",
                    marginBottom: "8px",
                    fontSize: "17px",
                    textDecoration: "underline",
                  }}
                >
                  Product Images
                </h6>
                <Row className="ImageContainer">
                  <div className="productImage">
                    {product?.product?.images?.map((product, index) => (
                      <div key={index} className="viewProduct">
                        <img className="viewProduct" src={product} alt=" " />
                      </div>
                    ))}
                  </div>
                </Row>
              </div>
            ))}
          </Row>

          <Row className="mt-lg-3">
            <h6 className="transactionHeading" style={{ color: "black" }}>
              Customer Information
            </h6>
            <Row className="mt-1">
              <Col>Name</Col>
              <Col className="value">
                {selectedCustomer?.customer?.name || "N/A"}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>Email</Col>
              <Col className="value">
                {selectedCustomer?.customer?.email || "N/A"}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>Mobile No</Col>
              <Col className="value">
                {selectedCustomer?.customer?.phoneNo || "N/A"}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>Shipping Address</Col>
              <Col className="value">
                {selectedCustomer?.shippingAddress || "N/A"}
              </Col>
            </Row>
          </Row>

          <Row className="mt-lg-2">
            <h6 className="transactionHeading" style={{ color: "black" }}>
              Assigned Tasks
            </h6>
            <DataTable columns={taskColumn} data={taskData} />
          </Row>

          <Row className="mt-lg-3">
            <h6 className="transactionHeading" style={{ color: "black" }}>
              Payment Details
            </h6>
            <Row className="mt-1">
              <Col>Total Amount</Col>
              <Col className="value">
                {paymentDetails?.totalAmount || "N/A"}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>Remaining Amount</Col>
              <Col className="value">
                {paymentDetails?.remainingAmount || "N/A"}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>Additional Comments</Col>
              <Col className="value">
                {paymentDetails?.additionalComments || "N/A"}
              </Col>
            </Row>
          </Row>

          <Row className="mt-lg-2">
            <h6 className="transactionHeading" style={{ color: "black" }}>
              Payment History
            </h6>
            <DataTable columns={paymentColumn} data={paymentData} />
          </Row>
          <Row>
            <Col className="subadminlastbutton">
              <div style={{ marginBottom: "30px" }}>
                <GlobalButton
                  name={"Record New Payment"}
                  fontFamily="Roboto"
                  background={"#dc2325"}
                  color={"#fff"}
                  className="mb-3"
                  onclick={() => setShowRecordPaymentModal(true)}
                />
              </div>
            </Col>
          </Row>
        </Row>
      </Container>
      {/* </Container> */}
    </>
  );
};

export default ViewOrderHistory;
