import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Container,
  Form,
  Button,
  InputGroup,
  ButtonGroup,
  Image,
} from "react-bootstrap";
import { MdEmail } from "react-icons/md";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoFilter, IoNotifications } from "react-icons/io5";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import { useNavigate } from "react-router-dom";

import "./AcPayroll.css";
import AddPayrollForm from "../AddPayrollForm/AddPayrollForm";
import ViewPayrollForm from "../ViewPayrollForm/ViewPayrollForm";
import { AccountsController } from "./../../../Redux/controllers/AccountsController";
import { useDispatch, useSelector } from "react-redux";
import Utility from "./../../../Utils/Utility";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";
import { logOut } from "../../../Redux/Slice/userSlice";

const employeeType = [
  {
    value: "sales",
    label: "Sales team",
  },
  {
    value: "production",
    label: "Production team",
  },
  {
    value: "procurement_and_inventory",
    label: "Procurement and Inventory Team",
  },
  {
    value: "delivery",
    label: "Delivery",
  },
  {
    value: "manager",
    label: "Reporting Manager",
  },
];
const AcPayroll = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((st) => st?.user);
  const [allEmployees, setAllEmployees] = useState([]);
  const [selectedDepartment, setSelcetdDepartment] = useState("all-employees");
  const [loading, setLoading] = useState(false);

  const handleForm = () => {
    navigate("/AddPayrollForm");
  };
  const handleview = (item) => {
    navigate("/ViewPayrollForm", { state: item });
  };
  const column = [
    {
      accessorKey: "slNo",
      header: "Sl No",
      size: 50,
    },
    {
      accessorKey: "employeeid",
      header: "Employee ID",
      size: 50,
    },

    {
      accessorKey: "fullname",
      header: "Full Name",
      size: 150,
    },
    {
      accessorKey: "phonenumber",
      header: "Mobile No",
      size: 50,
    },
    {
      accessorKey: "email",
      header: "Email",
      size: 50,
    },
    {
      accessorKey: "department",
      header: "Department",
      size: 50,
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 150,
    },
  ];
  const data = allEmployees
    ?.filter((item) =>
      selectedDepartment === "all-employees"
        ? true
        : item?.empDepartment === selectedDepartment
    )
    ?.map((item, i) => ({
      slNo: i + 1,
      employeeid: item?.empEmployeeID || "N/A",
      fullname: item?.name || "N/A",
      email: item?.email || "N/A",
      phonenumber: item?.phoneNo || "N/A",
      department: Utility.getDepartment(item?.empDepartment) || "N/A",
      action: (
        <GlobalButton
          name="View"
          background="#dc2329"
          color="#fff"
          fontFamily="Roboto"
          onclick={() => handleview(item)}
        />
      ),
    }));
  const getEmployeeData = async () => {
    try {
      setLoading(true);
      const { data } = await AccountsController.getEmployeeDetailsAPI(token);
      setAllEmployees(data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Utility.eToast(error?.response?.data?.message || error?.message);
      if (
        error?.response?.data?.message ===
        "token has expired , please login again"
      ) {
        dispatch(logOut());
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployeeData();
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(false);

  return (
    <>
      <Container id="transaction-primary-container">
        {showModal && <AddPayrollForm />}
        {modal && <ViewPayrollForm />}

        <Row className="second-box">
          <Col
            xs={8}
            sm={8}
            md={5}
            lg={4}
            xl={4}
            xxl={4}
            className="transaction-list"
            style={{ color: "black" }}
          >
            Employee Payroll
          </Col>

          <Col
            xs={10}
            sm={8}
            md={6}
            lg={5}
            xl={4}
            xxl={4}
            className="payrollselect"
          >
            <Form>
              <Form.Select
                aria-label="Default select example"
                name="trExCategory"
                className="accountsSelectTag"
                onChange={(e) => setSelcetdDepartment(e.target.value)}
              >
                <option value={"all-employees"}>All Employees</option>
                {employeeType?.map((item) => (
                  <option value={item?.value} key={item?.label}>
                    {item?.label}
                  </option>
                ))}
              </Form.Select>
            </Form>
          </Col>
        </Row>
        {!loading ? (
          <DataTable columns={column} data={data} />
        ) : (
          <div className=" Loader">
            <CircularIndeterminate />
          </div>
        )}
      </Container>
    </>
  );
};

export default AcPayroll;
