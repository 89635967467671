import React from "react";
import { Row, Col, Form, Image, Button, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import { FaRegEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import "./AppPayrollForm.css";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

export default function AddPayrollForm(props) {
  const [type, setType] = useState("password");
  const [icon, seticon] = useState(<FaEyeSlash />);
  const handletoggle = () => {
    if (type === "password") {
      seticon(<FaRegEye />);
      setType("text");
    }
    if (type === "text") {
      seticon(<FaEyeSlash />);
      setType("password");
    }
  };

  const navigate = useNavigate();
  return (
    <>
      <Container id="modal">
        <Row id="subadminmodalheader">
          <Col md={11} xs={10} id="ADNsubadmin">
            Add New Sub Admin Data
          </Col>
          <Col md={1} xs={2} id="subadmincrossicon">
            {" "}
            <RxCross1 id="crossicon" />{" "}
          </Col>
        </Row>
        <Row id="subadminmodalbody">
          <Col>
            <Form>
              <Row id="ADNsubadminRow">
                <Form.Label
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#642315",
                  }}
                >
                  Personal Information
                </Form.Label>

                <Col xs={12} md={7}>
                  <Form.Label id="ADNsubadminForm">Full Name</Form.Label>
                  <Form.Control
                    placeholder="Enter The Full Name"
                    id="subadminFormcontrol"
                  />
                </Col>
                <Col xs={12} md={5}>
                  <Form.Label id="ADNsubadminForm">Phone Number</Form.Label>
                  <Form.Control
                    placeholder="Enter The Phone Number"
                    id="subadminFormcontrol"
                  />
                </Col>
              </Row>
              <Row id="subadminmodalbody1">
                <Col xs={12} md={7}>
                  <Form.Label id="ADNsubadminForm">Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter The Email Address"
                    id="subadminFormcontrol"
                  />
                </Col>
                <Col xs={12} md={5}>
                  <Form.Label id="ADNsubadminForm">Transaction Date</Form.Label>
                  <br />
                  <Form.Control
                    type="date"
                    id="subadminFormcontrol"
                    class="custom-date-input"
                  />
                </Col>
              </Row>
              <Row id="ADNsubadminRow">
                <Col xs={12} md={12}>
                  <Form.Label id="ADNsubadminForm">Address</Form.Label>
                  <Form.Control
                    placeholder="Enter the Full Address"
                    id="subadminFormcontrol"
                  />
                </Col>
              </Row>
              <Row id="ADNsubadminRow">
                <Form.Label
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#642315",
                  }}
                >
                  Employment Details
                </Form.Label>

                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Position</Form.Label>
                  <Form.Control
                    placeholder="Enter The User Name ( generate by Employee)"
                    id="subadminFormcontrol"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Employee ID</Form.Label>
                  <Form.Control
                    placeholder="Enter The Password (Generate by Employee)"
                    id="subadminFormcontrol"
                  />
                </Col>
              </Row>
              <Row id="selectoptionrow">
                <Col xs={12} md={6}>
                  <Form.Label id="selectoptionlebel">Department</Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    style={{
                      color: "#595959BF",
                      fontSize: "13px",
                      fontFamily: "roboto",
                    }}
                    id="subadminFormcontrol"
                  >
                    <option id="optionlebel">select the department</option>
                    <option value="1" className="optionclass">
                      Sales
                    </option>
                    <option value="2" className="optionclass">
                      Production
                    </option>
                    <option value="3" className="optionclass">
                      Procurement and Inventory Team
                    </option>
                    <option value="4" className="optionclass">
                      Delivery team
                    </option>
                    <option value="5" className="optionclass">
                      Accounts team
                    </option>
                    <option value="6" className="optionclass">
                      Agents
                    </option>
                  </Form.Select>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Date of Hire</Form.Label>
                  <br />
                  <Form.Control
                    type="date"
                    id="subadminFormcontrol"
                    class="custom-date-input"
                  />
                </Col>
              </Row>
              <Row id="ADNsubadminRow">
                <Form.Label
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#642315",
                  }}
                >
                  Security Information
                </Form.Label>

                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter The User Name ( generate by Employee)"
                    id="subadminFormcontrol"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Password</Form.Label>
                  <Form.Control
                    type={type}
                    placeholder="Enter The Password"
                    id="subadminFormcontrol"
                    style={{ position: "relative" }}
                  />
                  <span onClick={handletoggle} id="Eyeicon">
                    {icon}
                  </span>
                </Col>
              </Row>
              <Row id="ADNsubadminRow">
                <Form.Label
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#642315",
                  }}
                >
                  Emergency Contact
                </Form.Label>
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">
                    Emergency Contact Name
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter The Emergency Contact Name"
                    id="subadminFormcontrol"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">
                    Emergency Contact Phone Number
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter The Emergency Contact Phone Number"
                    id="subadminFormcontrol"
                  />
                </Col>
              </Row>
              <Row id="ADNsubadminRow">
                <Col xs={12} md={12}>
                  <Form.Label id="ADNsubadminForm">
                    Additional Information
                  </Form.Label>
                  <Form.Control
                    placeholder="Write Additional information"
                    id="subadminFormcontrol"
                  />
                </Col>
              </Row>
              <Row id="selectoptionrow">
                <Form.Label
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#642315",
                  }}
                >
                  Salary Details
                </Form.Label>

                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">Salary Amount</Form.Label>
                  <Form.Control
                    placeholder="Specify your monthly or annual salary"
                    id="subadminFormcontrol"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="selectoptionlebel">Pay Frequency</Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    style={{
                      color: "#595959BF",
                      fontSize: "13px",
                      fontFamily: "roboto",
                    }}
                    id="subadminFormcontrol"
                  >
                    <option id="optionlebel">
                      Indicate how often you receive payment
                    </option>
                    <option value="1" className="optionclass">
                      Monthly
                    </option>
                    <option value="2" className="optionclass">
                      Bi-weekly
                    </option>
                    <option value="3" className="optionclass">
                      Daily
                    </option>
                    <option value="4" className="optionclass">
                      Contract
                    </option>
                    <option value="5" className="optionclass">
                      Other
                    </option>
                  </Form.Select>
                </Col>
              </Row>
              <Row id="selectoptionrow">
                <Col xs={12} md={6}>
                  <Form.Label id="selectoptionlebel">Payment Method</Form.Label>
                  <Form.Select
                    defaultValue="Choose..."
                    style={{
                      color: "#595959BF",
                      fontSize: "13px",
                      fontFamily: "roboto",
                    }}
                    id="subadminFormcontrol"
                  >
                    <option id="optionlebel">
                      {" "}
                      Choose your preferred payment method
                    </option>
                    <option value="1" className="optionclass">
                      Check
                    </option>
                    <option value="2" className="optionclass">
                      Bank Transfer / UPI{" "}
                    </option>
                    <option value="3" className="optionclass">
                      Cash
                    </option>
                  </Form.Select>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Label id="ADNsubadminForm">
                    Bank Account Information
                  </Form.Label>
                  <Form.Control
                    placeholder=" If you opt for direct deposit, provide your bank account details"
                    id="subadminFormcontrol"
                  />
                </Col>
              </Row>
              <Row id="ADNsubadminRow">
                <Col xs={12} md={12}>
                  <Form.Label id="ADNsubadminForm">Timestamps</Form.Label>
                  <Form.Control
                    placeholder="Date and Time of Registration (automatically generated)"
                    id="subadminFormcontrol"
                  />
                </Col>
              </Row>
              <Row id="ADNsubadminRow">
                <Col xs={8} md={11} id="discardbutton">
                  {" "}
                  <Button type="reset" id="subadminbuttton">
                    Discard
                  </Button>
                </Col>
                <Col xs={4} md={1} id="submitbutton">
                  {" "}
                  <Button type="submit" id="subadminbuttton">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
