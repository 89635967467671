import { HttpClient } from "./HttpClient";

const getOrderDetailsAPI = (token) => {
  let url = "order/get-all-orders";
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const getInvoiceDetailsAPI = (token) => {
  let url = "order/get-invoices";
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const getAllTasksAPI = (orderId, token) => {
  let url = "task/get-all-tasks?orderID=" + orderId;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const getPaymentHistoryAPI = (orderId, token) => {
  let url = "order/get-payment-history/" + orderId;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const recordPaymentDetailsAPI = (data, token) => {
  let url = "order/record-order-payment-details";
  return HttpClient.apiCaller(url, "PUT", data, token);
};
const getAllTransactionDetailsAPI = (type, token) => {
  let url = "transaction/get-all-transactions?type=" + type;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const createTransactionDetailsAPI = (data, token) => {
  let url = "transaction/create-transaction";
  return HttpClient.apiCaller(url, "POST", data, token);
};
const GenerateBillreportInvoice = (data) => {
  let url = "order/generate-custom-invoice";
  return HttpClient.apiCaller(url, "POST", data);
};
const getEmployeeDetailsAPI = (token) => {
  let url = "user/users-by-role-or-dept?role=employee";
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const getSalaryDetailsOfEmployeeAPI = (token, empId) => {
  let url = "salary/get-salary-details-by-employee/" + empId;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const getProductListAPI = (token) => {
  let url = "product/get-product-list";
  return HttpClient.apiCaller(url, "GET", {}, token);
};
const UpdateSalery = (data) => {
  let url = "salary/create-salary";
  return HttpClient.apiCaller(url, "POST", data);
};
const DownLoadSalery = (id) => {
  let url = `salary/download-salary-slip/${id}`;
  return HttpClient.apiCaller(url, "GET");
};
const generateTransactionInvoiceAPI = (token, id) => {
  let url = "transaction/download-transaction-invoice/" + id;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

const fetchExpenseBillAPI = (token, id) => {
  let url = "transaction/fetch-expense-bill/" + id;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
export const AccountsController = {
  getOrderDetailsAPI,
  getAllTasksAPI,
  getPaymentHistoryAPI,
  recordPaymentDetailsAPI,
  getAllTransactionDetailsAPI,
  createTransactionDetailsAPI,
  getEmployeeDetailsAPI,
  GenerateBillreportInvoice,
  getSalaryDetailsOfEmployeeAPI,
  getProductListAPI,
  UpdateSalery,
  DownLoadSalery,
  generateTransactionInvoiceAPI,
  fetchExpenseBillAPI,
  getInvoiceDetailsAPI,
};
