import React from "react";
import { Row, Col, Form, Image, Button, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./TransactionDetailForm.css";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function AddTransactionForm(props) {
  const navigate = useNavigate();
  return (
    <>
      <Container id="modal">
        <Row id="container-id">
          {/* <Col md={2}></Col> */}
          <Col>
            <Row id="transaction">
              <Col md={11} xs={10} id="transaction1">
                Transaction Details Form:
              </Col>
              <Col md={1} xs={2} id="transaction2">
                {" "}
                <RxCross1 id="crossicon" onClick={props.onHide} />
              </Col>
            </Row>

            <Row id="transaction3">
              <Col>
                <Form>
                  <Row id="transaction4">
                    <Col xs={12} md={7}>
                      <Form.Label id="transaction5">Transaction ID</Form.Label>
                      <Form.Control
                        placeholder="Enter The Transaction ID"
                        id="formid123"
                      />
                    </Col>
                    <Col xs={12} md={5}>
                      <Form.Label id="transaction5">
                        Transaction Date
                      </Form.Label>
                      <br />
                      <Form.Control
                        type="date"
                        id="formid123"
                        class="custom-date-input"
                      />
                    </Col>
                  </Row>
                  <Row id="transaction6">
                    <Col xs={12} md={12}>
                      <Form.Label id="transaction5">
                        Transaction Type
                      </Form.Label>
                      <br />
                      <Col id="formid1" xs={12} md={12}>
                        <Row id="formid4">
                          <Col md={2}>
                            <input
                              type="radio"
                              className="form-check-input"
                              name="fav"
                            />{" "}
                            Purchase{" "}
                          </Col>
                          <Col md={2}>
                            <input
                              type="radio"
                              className="form-check-input"
                              name="fav"
                            />{" "}
                            Sale
                          </Col>
                          <Col md={3}>
                            <input
                              type="radio"
                              className="form-check-input"
                              name="fav"
                            />{" "}
                            Return/Refund
                          </Col>
                          <Col md={2}>
                            <input
                              type="radio"
                              className="form-check-input"
                              name="fav"
                            />{" "}
                            Salary:
                          </Col>
                          <Col md={3}>
                            <input
                              type="radio"
                              className="form-check-input"
                              name="fav"
                            />{" "}
                            Others
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                  <Row id="transaction6">
                    <Col xs={12} md={12}>
                      <Form.Label id="transaction5">
                        Transaction Description
                      </Form.Label>
                      <Form.Control
                        placeholder="Brief explanation Transaction Description"
                        id="formid123"
                      />
                    </Col>
                  </Row>
                  <Row id="transaction6">
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">
                        Transaction Party
                      </Form.Label>
                      <Form.Control
                        placeholder="Name of Customer/Supplier"
                        id="formid123"
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">
                        Transaction Party Email Address
                      </Form.Label>
                      <Form.Control
                        placeholder="Enter The Customer/Supplier  Email "
                        id="formid123"
                      />
                    </Col>
                  </Row>
                  <Row id="transaction6">
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">
                        Transaction Amount
                      </Form.Label>
                      <Form.Control placeholder="Enter Amount" id="formid123" />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Payment Method</Form.Label>
                      <Form.Control
                        placeholder="Enter The Payment Method"
                        id="formid123"
                      />
                    </Col>
                  </Row>
                  <Form.Label id="form-lebel">Product Details</Form.Label>
                  <Row id="transaction6">
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Product Name</Form.Label>
                      <Form.Control
                        placeholder="Name of Product"
                        id="formid123"
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Quantity</Form.Label>
                      <Form.Control
                        placeholder="Quantity Sold/Purchased"
                        id="formid123"
                      />
                    </Col>
                  </Row>

                  <Row id="transaction6">
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Price per Unit</Form.Label>
                      <Form.Control placeholder="$25000.00" id="formid123" />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Total Price</Form.Label>
                      <Form.Control
                        placeholder="Total Price for Product"
                        id="formid123"
                      />
                    </Col>
                  </Row>

                  <Form.Label id="form-lebel">
                    Additional Charges (if applicable)
                  </Form.Label>
                  <Row id="transaction6">
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Tax</Form.Label>
                      <Form.Control
                        placeholder="Enter Tax Amount"
                        id="formid123"
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Shipping Fee</Form.Label>
                      <Form.Control placeholder="Shipping Fee" id="formid123" />
                    </Col>
                  </Row>
                  <Row id="transaction6">
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Discounts</Form.Label>
                      <Form.Control
                        placeholder="Enter Discounts amount"
                        id="formid123"
                      />
                    </Col>
                  </Row>
                  <Row id="transaction6">
                    <Col xs={12} md={12}>
                      <Form.Label id="transaction5">
                        Total Transaction Amount
                      </Form.Label>
                      <Form.Control
                        placeholder="Enter Total Amount"
                        id="formid123"
                      />
                    </Col>
                  </Row>
                  <Form.Label id="form-lebel">Payment Details</Form.Label>
                  <Row id="transaction6">
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Payment Status</Form.Label>
                      <Form.Control
                        placeholder="Enter Payment Status"
                        id="formid123"
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">
                        Transaction Reference/ID
                      </Form.Label>
                      <Form.Control
                        placeholder="Payment Transaction ID"
                        id="formid123"
                      />
                    </Col>
                  </Row>
                  <Row id="transaction6">
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">Payment Status</Form.Label>
                      <Form.Control
                        placeholder="Enter Payment Status"
                        id="formid123"
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Label id="transaction5">
                        Transaction Reference/ID
                      </Form.Label>
                      <Form.Control
                        placeholder="Payment Transaction ID"
                        id="formid123"
                      />
                    </Col>
                  </Row>
                  <Row id="transaction6">
                    <Col xs={12} md={12}>
                      <Form.Label id="transaction5">
                        Shipping Address
                      </Form.Label>
                      <Form.Control
                        placeholder="Customer's Shipping Address"
                        id="formid123"
                      />
                    </Col>
                  </Row>
                  <Row id="transaction6">
                    <Col xs={12} md={12}>
                      <Form.Label id="transaction5">Billing Address</Form.Label>
                      <Form.Control
                        placeholder="Customer's Billing Address"
                        id="formid123"
                      />
                    </Col>
                  </Row>
                  <Row id="transaction6">
                    <Col xs={12} md={12}>
                      <Form.Label id="transaction5">
                        Additional Notes
                      </Form.Label>
                      <Form.Control
                        placeholder="Any additional notes or comments regarding the sale"
                        id="formid123"
                      />
                    </Col>
                  </Row>

                  <Row id="transaction6">
                    <Col xs={8} md={10} id="transaction7">
                      {" "}
                      <Button
                        variant="secondary"
                        type="reset"
                        id="transaction10"
                      >
                        Discard
                      </Button>
                    </Col>
                    <Col xs={4} md={2} id="transaction8">
                      {" "}
                      <Button
                        variant="primary"
                        type="submit"
                        id="transaction10"
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
          {/* <Col md={2}></Col> */}
        </Row>
      </Container>
    </>
  );
}

export default AddTransactionForm;
