import React, { useEffect } from "react";
import "./TopNavBar.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import { IoSettingsOutline } from "react-icons/io5";
import { MdNotificationsNone } from "react-icons/md";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { GoDotFill } from "react-icons/go";
import mmlogo from "../../Inventory_material_request/assets/dashboard/mmlogo.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../Redux/Slice/userSlice";
import NotificationModal from "../../Inventory_material_request/pages/Modals/NotificationModal/NotificationModal";
import { useState } from "react";
import { UserController } from "../../Redux/controllers/UserController";
import LogoutModal from "../../Accounts/pages/Modals/LogoutModal/LogoutModal";

function TopNavBar() {
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.userInfo._id);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleConfirmLogout = (value) => {
    if (value === "ok") {
      handleLogout();
    }
    setModal(false);
  };

  const handleLogout = (value) => {
    dispatch(logOut());
    localStorage.clear();
    navigate("/");
  };

  const [modal, setModal] = useState(false);
  const [allNotification, setAllNotification] = useState("");

  const getNotification = async () => {
    try {
      const res = await UserController.getAllNotification(token, userId);
      console.log("RESP", res.data.data);
      setAllNotification(res.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getNotification();
  }, [modal, setModal]);

  const handelSubmit = ()=>{
    setModal2(true);
    // handleLogout()
  }

  const [modal2 , setModal2] = useState(false)
  
  return (
    <Container>
      <Row>
        <Col className="tnb2">
          <div className="tnb5" onClick={() => setModal(true)}>
            <MdNotificationsNone className="tnb3" />
            <span>
              {
                allNotification.length > 0 && <GoDotFill className="tnb6" />
              }
            </span>
          </div>
          <div className="tnb4" onClick={handelSubmit}>
            Logout
            <span>
              <RiLogoutBoxRLine className="tnb3" />
            </span>
          </div>
        </Col>
      </Row>
      {modal && (
        <NotificationModal
          show={modal}
          onHide={() => {
            setModal(false);
          }}
        />
      )}

      {
        modal2  &&  (
          <LogoutModal
            show={modal2}
            onHide={() => {
              setModal2(false);
            }}
            onConfirm={handleConfirmLogout}
          />
        )
      }
    </Container>
  );
}

export default React.memo(TopNavBar);
