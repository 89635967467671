import { ToastContainer, Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import parse from "html-react-parser";
import NumberFormat from "react-number-format";
const sToast = (msg) => {
  toast.success(msg, {
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
  });
};

const eToast = (msg) => {
  toast.error(msg, {
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
  });
};

function formatDate(dateString) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(dateString);
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${day} ${month}, ${year}`;
}
const getStatusColor = (status) => {
  switch (status) {
    case "pending":
      return "#C03744";
    case "confirmed":
      return "#457B9D";
    case "processing":
      return "#EB9F14";
    case "progress":
      return "#EB9F14";
    case "shipped":
      return "#855CD0";
    case "delivered":
      return "#15B097";
    case "completed":
      return "#15B097";
    default:
      return "#C03744";
  }
};
const HTMLParser = (htmlString) => {
  return parse(htmlString);
};

const getDepartment = (dept) => {
  switch (dept) {
    case "sales":
      return "Sales";
    case "production":
      return "Production";
    case "procurement_and_inventory":
      return "Inventory";
    case "delivery":
      return "Delivery";
    case "accounts":
      return "Accountant";
    case "manager":
      return "Manager";

    default:
      return "N/A";
  }
};
const getPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case "bank_transfer_or_upi":
      return "Bank Transfer / UPI";
    case "cash":
      return "Cash";
    case "check":
      return "Check";
    default:
      return "N/A";
  }
};

const MoneyFormatter = (value) => {
  if (value == null) return "N/A";

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(value);
};

const valueFormatter = (value = 0) => {
  if (value >= 1e9) {
    return (value / 1e9).toFixed(1).replace(/\.0$/, "") + "b";
  }
  if (value >= 1e6) {
    return (value / 1e6).toFixed(1).replace(/\.0$/, "") + "m";
  }
  if (value >= 1e3) {
    return (value / 1e3).toFixed(1).replace(/\.0$/, "") + "k";
  }
  return value;
};
function getLastMonthName() {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonthIndex = new Date().getMonth();
  const lastMonthIndex = (currentMonthIndex - 1 + 12) % 12;
  return monthNames[lastMonthIndex];
}

const Utility = {
  sToast,
  eToast,
  formatDate,
  getStatusColor,
  HTMLParser,
  getPaymentMethod,
  getDepartment,
  MoneyFormatter,
  valueFormatter,
  getLastMonthName,
};

export default Utility;
