import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import DashBoard from "../Inventory_material_request/pages/DashBoard/DashBoard";
import OrderHIstory from "../Inventory_material_request/pages/OrderHistory/OrderHIstory";
import CheckMaterialRequest from "../Inventory_material_request/pages/CheckMaterialRequest/CheckMaterialRequest";
import Productform from "../Inventory_material_request/pages/PruductForm/Productform";
import AcDashboard from "../Accounts/pages/AcDashboard/AcDashboard";
import AcOrderHistory from "../Accounts/pages/AcOrderHistory/AcOrderHistory";
import AcPayroll from "../Accounts/pages/AcPayroll/AcPayroll";
import AcTransaction from "../Accounts/pages/AcTransaction/AcTransaction";
import Billandreport from "../Accounts/pages/BillsAndReport/Billandreport";
import AddPayrollForm from "../Accounts/pages/AddPayrollForm/AddPayrollForm";
import ViewPayrollForm from "../Accounts/pages/ViewPayrollForm/ViewPayrollForm";
import AddTrasactionForm from "../Accounts/pages/AddTransactionForm/AddTrasactionForm";
import ViewTransactionForm from "../Accounts/pages/ViewTransactionForm/ViewTransactionForm";
import ViewOrderHistory from "../Accounts/pages/ViewOrderHistory/ViewOrderHistory";
import GenerateInvoice from "../Accounts/pages/BillGenerateInvoice/GenerateInvoice";
import ViewSalesData from "../Accounts/pages/ViewSalesData/ViewSalesData";
import SalesData from "../Accounts/pages/TSalesData/SalesData";
import SaleForm from "../Accounts/pages/SaleAddForm/SaleForm";
import ExpenseForm from "../Accounts/pages/ExpenseForm/ExpenseForm";
import ViewExpenseData from "../Accounts/pages/ViewExpenseData/ViewExpenseData";
import BillGenerateInvoice from "../Accounts/pages/Bill_GenerateInvoice/BillGenerateInvoice";
import { useSelector } from "react-redux";
export default function RootRouter() {
  const user = useSelector((state) => state?.user?.userInfo?.empDepartment);
  //  console.log("from root router",user);
  return (
    <>
      {/* Inventory Ruter */}

      {user !== "accounts" && (
        <Routes>
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/productform" element={<Productform />} />
          <Route
            path="/CheckMaterialRequest"
            element={<CheckMaterialRequest />}
          />
          <Route path="/orderhistory" element={<OrderHIstory />} />
        </Routes>
      )}
      {/* Inventory Ruter */}
      <Routes>
        <Route
          path="/"
          element={
            user !== "accounts" ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/AcDashboard" />
            )
          }
        />
      </Routes>

      {/* Acoounts navigation */}

      {user === "accounts" && (
        <Routes>
          <Route path="/AcDashboard" element={<AcDashboard />} />
          <Route path="/AcPayroll" element={<AcPayroll />} />
          <Route path="/AcTransaction" element={<AcTransaction />} />
          <Route path="/AcOrderHistory" element={<AcOrderHistory />} />
          <Route path="/AcBillReport" element={<Billandreport />} />
          <Route path="/AddPayrollForm" element={<AddPayrollForm />} />
          <Route path="/ViewPayrollForm" element={<ViewPayrollForm />} />
          <Route path="/AddTrasactionForm" element={<AddTrasactionForm />} />
          <Route
            path="/ViewTransactionForm"
            element={<ViewTransactionForm />}
          />
          <Route path="/ViewOrderHistory" element={<ViewOrderHistory />} />
          <Route path="/GenerateInvoice" element={<GenerateInvoice />} />
          <Route path="/ViewSalesData" element={<ViewSalesData />} />
          <Route path="/SaleForm" element={<SaleForm />} />
          <Route path="/ExpenceForm" element={<ExpenseForm />} />
          <Route path="/ViewExpenseData" element={<ViewExpenseData />} />
          <Route
            path="/BillGenerateInvoice"
            element={<BillGenerateInvoice />}
          />
        </Routes>
      )}
      {/* Acoounts navigation */}
    </>
  );
}
