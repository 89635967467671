import React from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import "./DataTable.css";

const DataTable = ({
  columns,
  data,
  enablePagination = true,
  enableBottomToolbar = true,
  enableGlobalFilter = true,
}) => {
  const table = useMaterialReactTable({
    columns: columns.map((column) => ({
      ...column,
      render: (rowData) => {
        return column.accessorKey === "action"
          ? rowData.action
          : rowData[column.accessorKey];
      },
    })),
    data,
    enablePagination: enablePagination,
    enableBottomToolbar: enableBottomToolbar,
    enableGlobalFilter: enableGlobalFilter,
    muiTableHeadCellProps: {
      sx: {
        background: "#F4F3F0",
        color: "black",
      },
    },
  });
  return <MaterialReactTable table={table} />;
};

export default DataTable;
