import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { IoFilter } from "react-icons/io5";
import { GoPlusCircle } from "react-icons/go";
import "./ExpensesData.css";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import { useNavigate } from "react-router-dom";
import { AccountsController } from "../../../Redux/controllers/AccountsController";
import { useDispatch, useSelector } from "react-redux";
import Utility from "../../../Utils/Utility";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";
import { logOut } from "../../../Redux/Slice/userSlice";

const ExpensesData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((st) => st?.user);
  const [expenseData, setExpenseData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllTransactionData = async () => {
    try {
      setLoading(true);
      const { data } = await AccountsController.getAllTransactionDetailsAPI(
        "expense",
        token
      );
      setExpenseData(data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Utility.eToast(error?.response?.data?.message || error?.message);
      if (
        error?.response?.data?.message ===
        "token has expired , please login again"
      ) {
        dispatch(logOut());
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllTransactionData();
  }, []);
  const columns = [
    {
      accessorKey: "slno",
      header: "Sl No",
      size: 50,
    },
    {
      accessorKey: "transactionId",
      header: "Transaction ID",
      size: 50,
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 50,
    },
    {
      accessorKey: "transactionPartyName",
      header: "Name",
      size: 100,
    },
    {
      accessorKey: "transactionPartyMobile",
      header: "Mobile No",
      size: 100,
    },
    {
      accessorKey: "amount",
      header: "Amount",
      size: 50,
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 100,
    },
  ];

  const handelView = (item) => {
    navigate("/ViewExpenseData", { state: item });
  };

  const handleexpenseForm = () => {
    navigate("/ExpenceForm");
  };

  const data = expenseData?.map((item, idx) => {
    return {
      slno: idx + 1,
      date: Utility?.formatDate(item?.trDate),
      transactionId: "#" + item?.trID,
      transactionPartyName: item?.trExPartyName,
      transactionPartyMobile: item?.trExPartyMobileNo,
      amount: Utility.MoneyFormatter(item?.trExTotalAmount),
      action: (
        <GlobalButton
          name="View"
          background="red"
          color="#fff"
          onclick={() => handelView(item)}
        />
      ),
    };
  });

  return (
    <Container className="expenseDataContainer">
      <Row className="expense-head">
        <Col sm={6} xs={12} className="topexpense" style={{ color: "black" }}>
          Expenses Data
        </Col>
        <Col sm={6} xs={12} className="topsecondexpense">
          <GlobalButton
            name="Add New"
            background="#dc2329"
            color="#fff"
            icon={<GoPlusCircle />}
            fontWeight="500"
            onclick={handleexpenseForm}
          />
          {/* <GlobalButton
            name="Filter"
            background="#e4cdaa"
            color="#642315"
            icon={<IoFilter />}
            fontWeight="500"
          /> */}
        </Col>
      </Row>
      <Row>
        {!loading ? (
          <DataTable columns={columns} data={data} />
        ) : (
          <div className=" Loader">
            <CircularIndeterminate />
          </div>
        )}
      </Row>
    </Container>
  );
};

export default ExpensesData;
